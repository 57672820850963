import React, { useEffect } from 'react';
import { Link } from "react-scroll";
import ReactGA from 'react-ga'
import './faq.css'
import FAQ from "../../components/Questions/FAQ";
import questions from "./questions";

function SectionItem({ children, id, title, titleInnerHtml }) {
  return (
    <div id={id} className='mb-5 md:mb-20 '>
      {!titleInnerHtml && <div className="font-bold lg:font-semibold break-normal section-title text-4xl lg:text-5xl mb-8">{title}</div>}
      {titleInnerHtml && <div className="font-bold lg:font-semibold break-normal section-title  text-4xl lg:text-5xl mb-8">What is <span className='faq-green'>Cashlet</span></div>}
      {/* <!--Card--> */}
      {children}
      {/* <!--/Card--> */}
    </div>
  );
}

function FAQPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    // console.log("google faq triggered");
  }, [])

  return (
    <section className="py-0 md:py-16">
      <div className='px-4 md:px:0 pt-6 md:pt-0 md:container mx-auto'>
        <div className='faq-black text-left'>
          <h1 className="text-4xl lg:text-7xl slide-in-bottom-h1 mb-4">
            Frequently asked questions
          </h1>
          <div className='text-base lg:text-2xl'>
            We're here to help with any questions you have about plans, pricing, and supported features. Cant find  what you are looking for? You can reach out to us via our email: <span className='underline'>team@cashlet.co.ke</span>
          </div>
        </div>
        {/* -------------------------------------------------- */}
        <div className="w-full flex flex-wrap mx-auto mt-6 md:mt-16">
          {/* -------------------------------------------------- */}
          <div className="w-full relative lg:w-2/6 text-xl text-gray-800 leading-normal">
            <div
              className="w-full sticky inset-0 hidden max-h-full lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-2 lg:my-0 border border-gray-400 lg:border-transparent bg-white shadow lg:shadow-none lg:bg-transparent z-20"
              style={{ top: "4em" }}
              id="menu-content"
            >
              <h3 className="text-base font-bold text-gray-700">FAQ</h3>
              <h1 className="text-2xl font-bold py-2 lg:pb-8 text-gray-700">
                Table of Contents
              </h1>
              <ul className="list-reset py-2 md:py-0">
                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section1"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    What is Cashlet
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section2"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    Safety & Security
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section3"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    Account Activation
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section4"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Deposits</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section5"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    Withdrawals
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section6"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    Interest & Returns
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section7"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Savings & Investment Funds</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section8"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Goal Lock</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section9"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Pricing & Fees</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section10"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Account Management</span>
                </Link>
              </ul>
            </div>
          </div>
          {/* -------------------------------------------------- */}
          <section className="md:container w-full lg:w-4/6">
            <div className=" lg:hidden font-bold my-2 text-base">
              FAQ
            </div>
            <SectionItem id="#section1" title="" titleInnerHtml>
              <FAQ>
                {questions[1].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
            <SectionItem id="#section2" title="Safety & Security">
              <FAQ>
                {questions[2].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
            <SectionItem id="#section3" title="Account Activation">
              <FAQ>
                {questions[3].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
            <SectionItem id="#section4" title="Deposits">
              <FAQ>
                {questions[4].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
            <SectionItem id="#section5" title="Withdrawals">
              <FAQ>
                {questions[5].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
            <SectionItem id="#section6" title="Interest & Returns">
              <FAQ>
                {questions[6].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
            <SectionItem id="#section7" title="Savings & Investment Funds">
              <FAQ>
                {questions[7].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
            <SectionItem id="#section8" title="Goal Lock">
              <FAQ>
                {questions[8].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
            <SectionItem id="#section9" title="Pricing & Fees">
              <FAQ>
                {questions[9].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
            <SectionItem id="#section10" title="Account Management">
              <FAQ>
                {questions[10].map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question1
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer1 id={question.id}>
                      {question.description}
                    </FAQ.Answer1>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </SectionItem>
          </section>
        </div>
      </div>
    </section>
  );
}

export default FAQPage;
