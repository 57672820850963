/*eslint-disable*/
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Sidebar({ setNavbarOpen, navbarOpen }) {
  return (
    <>
      <div
        className={
          'absolute right-0 top-16 shadow rounded-tl-2xl rounded-bl-2xl w-2/6  flex-grow items-center bg-white lg:hidden' +
          (navbarOpen ? ' block' : ' hidden')
        }
      >
        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto p-2">
          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            exact
            activeClassName="nav-item-active"
            className="w-full  hover:text-gray-600 text-black px-3 lg:px-6 py-1 mb-2 lg:py-2 ml-auto flex items-center text-center text-xs vxs:text-base"
            to="/"
          >Home
            </NavLink>

          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            activeClassName="nav-item-active"
            className="w-full hover:text-gray-600 text-black px-3 lg:px-6 py-1 mb-2  ml-auto lg:py-2 flex items-start text-left lg:text-center text-xs vxs:text-base font-normal "
            to="/about-us"
          >About us
          </NavLink>

          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            activeClassName="nav-item-active"
            className="w-full  hover:text-gray-600 text-black px-3 lg:px-6 py-1  mb-2  ml-auto lg:py-2 flex items-center text-center text-xs vxs:text-base font-normal"
            to="/FAQ"
          >FAQ
          </NavLink>

          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            activeClassName="nav-item-active"
            className="w-full  hover:text-gray-600 text-black px-3 lg:px-6 py-1  mb-2  ml-auto lg:py-2 flex items-center text-center text-xs vxs:text-base font-normal"
            to="/security"
          >Security
          </NavLink>
          
          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            activeClassName="nav-item-active"
            className="w-full  hover:text-gray-600 text-black px-3 lg:px-6 py-1  mb-2  ml-auto lg:py-2 flex items-center text-center text-xs vxs:text-base font-normal"
            to="/Blog"
          >Blog
          </NavLink>
        </ul>
      </div>
    </>
  );
}
