import classNames from 'classnames';
import React, { useState } from 'react';
import "./questions.css";
import upArrow from '../../assets/img/chevron-up.svg';
import bottomArrow from '../../assets/img/chevron-down.svg';
import close from '../../assets/img/close.svg';

function FAQ({ children, defaultOpen = [0], open: openFromProps, onToggle: onToggleFromProps = () => { } }) {
  const isControlled = () => (openFromProps ? true : false);
  const [open, setIsOpen] = useState(defaultOpen);
  const getOpen = () => (isControlled() ? openFromProps : open);
  const isOpen = (index) => {
    return getOpen().includes(index) ? true : false;
  };
  const onToggle = (index) => {
    if (isControlled()) {
      onToggleFromProps(index);
    } else {
      if (getOpen().includes(index)) {
        setIsOpen(getOpen().filter((item) => item !== index));
      } else {
        setIsOpen([index]);
        // setIsOpen([...getOpen(), index]);
      }
      onToggleFromProps(index);
    }
  };
  return (
    <section className="space-y-6">
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          isOpen: isOpen(index),
          onToggle: () => onToggle(index),
        });
      })}
    </section>
  );
}


function Question({ title, isOpen, answerId, onToggle }) {
  return (
    <header
      className="flex justify-between items-start cursor-pointer select-none gap-2"
      aria-expanded={isOpen}
      aria-controls={answerId}
      onClick={onToggle}
    >
      <div className="font-bold faq-question sm:text-base md:text-xl">
        {title}
      </div>
      <div className="icon-wrapper">
        {isOpen ? <img src={upArrow} alt="Up arrow" /> : <img src={bottomArrow} alt="Bottom arrow" />}
      </div>
    </header>
  );
}
function Question1({ title, isOpen, answerId, onToggle }) {
  return (
    <header
      className="flex justify-between items-start cursor-pointer select-none gap-2"
      aria-expanded={isOpen}
      aria-controls={answerId}
      onClick={onToggle}
    >
      <div className="font-semibold faq-question1 sm:text-base md:text-2xl">
        {title}
      </div>
      <div className="icon-wrapper">
        {isOpen ? <img src={close} alt="close"/> : <img src={close} alt="open" className='open-icon'/>}
      </div>
    </header>
  );
}
function Answer({ children, id, isOpen }) {
  const mergedClassname = classNames({
    hidden: !isOpen,
  });
  return (
    <div className={mergedClassname} id={id}>
      <div className="faq-answer text-base mt-7" dangerouslySetInnerHTML={{__html: children}}>
      </div>
    </div>
  );
}

function Answer1({ children, id, isOpen }) {
  const mergedClassname = classNames({
    hidden: !isOpen,
  });
  return (
    <div className={mergedClassname} id={id}>
      <div className="faq-answer1 text-base mt-7">
        {children}
      </div>
    </div>
  );
}

function QAItem({ children, isOpen, onToggle }) {
  return (
    <article className="pb-7 text-left faq-item">
      <div>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            isOpen: isOpen,
            onToggle: onToggle,
          });
        })}
      </div>
    </article>
  );
}

FAQ.QAItem = QAItem;
FAQ.Question = Question;
FAQ.Question1 = Question1;
FAQ.Answer = Answer;
FAQ.Answer1 = Answer1;

export default FAQ;
