import React from 'react';
import { Link } from "react-scroll";
import collapse from "../../assets/img/collapse.png"
import expand from "../../assets/img/expand.png"
import bulletPoints from "../../assets/img/Ellipse_62.png"
import ReactGA from 'react-ga'


function SectionItem({ children, id, title, isMobile, opactHandl, TheState, TheState_String }) {
  return (
    <div id={id}  >
      {/* <!--Title--> */}

      <div className="flex flex-row relative ">
        <h2 className="font-sans font-bold break-normal text-gray-900 py-2 pb-1 text-2xl md:text-5xl w-10/12 md:w-full">
          {title}
        </h2>

        {isMobile ? (
          <div >
            <img
              onClick={() => {
                // setSectionVisibility({ ["Section1_1"]: !SectionVisibility.Section1_1 })
                opactHandl(TheState_String)
              }}
              // src={expand} alt="" className="absolute inset-y-1/2 right-2" />
              src={TheState ? expand : collapse} alt="" className="  absolute inset-y-1/2 right-2" />
          </div>
        ) : null}

        {/* {isMobile ? (
          <div>
            <img onClick={() => {
              setSectionVisibility({ ["Section1_1"]: !SectionVisibility.Section1_1 })
            }}
              src={SectionVisibility.Section1_1 ? expand : collapse} alt="" className="  absolute inset-y-1/2 right-2" />
          </div>
        ) : null} */}
      </div>


      {/* <!--Card--> */}
      {children}
      {/* <!--/Card--> */}

      {/* <!--divider--> */}
      <hr className="bg-gray-200 my-2 py-px opacity-25" />
    </div>
  );
}

function PrivacyPolicyPage() {
  // const focusDiv = (ele) => {
  //   let offsetTop = document.getElementById(ele).offsetTop;

  //   if (offsetTop) {
  //     window.scrollTo({
  //       top: offsetTop - 100,
  //       behavior: 'smooth',
  //     });
  //   }
  // };
  let initialState = true
  

  let isMobile = window.innerWidth < 767

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  if (!isMobile) {
    initialState = false
  }
  const [SectionVisibility, setSectionVisibility] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      Section1_1: false,
      Section1_2: initialState,
      Section1_3: initialState,
      Section1_4: initialState,
      Section1_5: initialState,
      Section1_6: initialState,
      Section1_7: initialState,
      Section1_8: initialState,
      Section1_9: initialState,
      Section1_10: initialState,
      Section1_11: initialState,
    }
  );

  const OpacityHandler = (le_string) => {
    setSectionVisibility({ [le_string]: !SectionVisibility[le_string] })
    // console.log(le_string)
    // console.log(SectionVisibility[le_string]);

  }


  return (
    <section className="relative py-0 md:py-16">
      <div className="max-w-6xl md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl  mx-auto">
        {/* -------------------------------------------------- */}
        <div className="container w-full flex flex-wrap mx-auto px-4 md:px-2 pt-0 lg:pt-16 mt-16">
          {/* -------------------------------------------------- */}
          <div className="w-full lg:w-2/6 px-6 text-xl text-gray-800 leading-normal">
            <div
              className="w-full sticky inset-0 hidden max-h-full lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-2 lg:my-0 border border-gray-400 lg:border-transparent bg-white shadow lg:shadow-none lg:bg-transparent z-20"
              style={{ top: '6em' }}
              id="menu-content"
            >
              <h3 className="text-base font-bold text-gray-700">Privacy Policy</h3>
              <h1 className="text-2xl font-bold py-2 lg:pb-8 text-gray-700">Table of Contents</h1>
              <ul className="list-reset py-2 md:py-0">

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section1"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Who are we?</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section2"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Highlights from this policy</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section3"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">The information we hold about you</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section4"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">How we use the information we collect from you</span>
                </Link>


                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section5"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Who we share your data with</span>
                </Link>


                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section6"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">How long we keep your information</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section7"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Your rights</span>
                </Link>


                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section8"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Where we store your data</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section9"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">How to make a compliant</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section10"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Changes to this policy</span>
                </Link>
              </ul>
            </div>
          </div>
          {/* -------------------------------------------------- */}
          <section className="w-full lg:w-4/6">
            <SectionItem
              id="#section1"
              title="Who are we?"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_1}
              TheState_String="Section1_1">

              <div className={SectionVisibility.Section1_1 ? 'hidden' : 'visible space-y-8  '}>
                <div className=" space-y-8 ">
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We are Cashlet app (‘we, ‘our’, ‘us’) and we operate under the name Cashlet. Cashlet is committed to
                    maintaining the confidentiality, integrity and security of any personal information about our users.
                    This Privacy Policy explains how and why we use your personal information, that is provided on our
                    Web site located at{' '}
                    <a className="text-primary-green underline font-semibold" href="/">
                      www.cashlet.co.ke
                    </a>{' '}
                    (the “Site”) and our mobile application (collectively, “Cashlet”).
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Cashlet stresses its privacy and security standards to guard against identity theft and provide
                    security for your personal information. We regularly re-evaluate our privacy and security policies
                    and adapt them as necessary to deal with new challenges.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Got a question about something in this notice
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      <div>
                        Chat with us through the app or send us an email at{' '}
                        {/* <a className="text-primary-green underline font-semibold" href="malto:support@cashlet.co.ke">
                        support@cashlet.co.ke
                      </a> */}
                        <a
                          className="text-primary-green underline font-semibold"
                          href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}</a>{' '}
                      </div>

                    </div>

                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Write to us at Cashlet, 4th Floor, Laiboni Center, Lenana Road, Kilimani, Nairobi, Kenya
                    </div>

                  </ul>
                </div>{' '}
              </div>
            </SectionItem>
            <SectionItem
              id="#section2"
              title="Highlights of this policy"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_2}
              TheState_String="Section1_2">
              <div className={SectionVisibility.Section1_2 ? 'hidden' : 'visible space-y-8  '}>
                <div className=" space-y-8 ">
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We process data collected from you, and third parties, to make Cashlet work for you, and comply with
                    regulatory obligations.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Once we have received your information, we use strict procedures and security features to prevent
                    unauthorized access. Information we deem sensitive are stored using state-of-the-art symmetric
                    encryption (AES). We are bound and empowered by the Kenya Data Protection Bill to keep your
                    information safe and we do this with pride.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    In short, most importantly we use:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Your personal details (names, address, date of birth, email, phone number, identify documents), to
                      comply with our obligations to know our customer
                    </div>

                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Data about your use of Cashlet to help us make Cashlet better
                    </div>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We share your data with trusted service providers and government entities to fulfil our contract
                    with you, or comply with regulations:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Payment service provider (M-Pesa) — who enable money transfers to enable you to deposit and
                      withdraw
                    </div>
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />

                      Regulated Investment Providers (ICEA Lion, Etica Capital and Orient Asset Managers) —
                      who make your savings and investing possible
                    </div>
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      KYC Provider (Smile Identity) — who help us check the identity information you give us</div>
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />

                      Our regulator (the Capital Markets Authority of Kenya) — as part of their monitoring activities
                      and to get approvals to offer our services to you
                    </div>
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />

                      Law enforcement and other government entities — where we are required to do so to comply with our
                      regulatory and legal obligations
                    </div>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You have the right to see, erase, or challenge the data we hold about you (among other rights). Our
                    support team can help with this. Read the full privacy policy for further details on how we use your
                    information.
                  </p>
                </div>{' '}
              </div>
            </SectionItem>
            <SectionItem
              id="#section3"
              title="The information we hold about you"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_3}
              TheState_String="Section1_3">
              <div className={SectionVisibility.Section1_3 ? 'hidden' : 'visible space-y-8  '}>
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Information you give us on sign up
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    This is information necessary to provide the basic Cashlet app Service (to fulfil the contract
                    between us) and to comply with regulatory obligations to basic ‘Know Your Customer’ (KYC):
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative " > <img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />First and last name</div>
                    <div className="flex flex-row relative " > <img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />Phone number</div>
                    <div className="flex flex-row relative " > <img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />Email</div>
                  </ul>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Information you give us after sign up to verify your identity
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    This is information we require to secure your account, ensure that you are who you say you are,
                    unlock additional features, and to perform our regulatory obligations:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />Date of birth</div>
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />Address</div>
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Identity documents and proof of nationality – for example picture of your national ID or passport,
                      selfie, and KRA pin.
                    </div>
                  </ul>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Information we automatically collect from your use of Cashlet
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    When you use Cashlet, or visit our website, we automatically collect information, including personal
                    information, about the parts of the Cashlet Service you use, and how you use them. We collect this
                    information to give you services in a safe and lawful way, and to keep improving them:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative " ><img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />
                      Details about payments to and from your Cashlet account, and your savings activity</div>
                    <div className="flex flex-row relative " ><img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />
                      Details about how you use our app</div>
                    <div className="flex flex-row relative " ><img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />
                      Information you give us through Cashlet chat</div>
                    <div className="flex flex-row relative " ><img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />

                      The mobile network and operating system you use, so we can analyse how our app works and fix any
                      issues
                    </div>
                    <div className="flex flex-row relative " ><img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />

                      Your IP address and device ID for security reasons (we’ll link your mobile phone number with your
                      device)
                    </div>
                    <div className="flex flex-row relative " ><img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />

                      Information about your device — your visits to and use of the site or the Service
                    </div>
                    <div className="flex flex-row relative " ><img
                      src={bulletPoints}
                      className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                    />

                      Information about your use of the Site — length of visit, page views, website navigation and
                      search terms that you use, referral source/exit pages
                    </div>
                  </ul>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Information we get from external sources
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We receive the following personal information about you from our third party service providers who
                    assist us in providing some or all of the Service
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Our KYC (Know your customer) provider — in order to perform identify verification and
                      money-laundering checks
                    </div>
                    <div className="flex flex-row relative " >
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Public and Commercial Sources — in order to perform our KYC obligations, we might collect
                      information from public sources such as sanctions lists or credit reference agencies
                    </div>
                  </ul>
                </div>{' '}
              </div>
            </SectionItem>
            <SectionItem
              id="#section4"
              title="How we use the information we collect from you"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_4}
              TheState_String="Section1_4">
              <div className={SectionVisibility.Section1_4 ? 'hidden' : 'visible space-y-8  '}>
                <div className=" space-y-8 ">
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    To provide and improve the Cashlet product — we process the information we collect given our
                    legitimate interest in improving the Cashlet Service, and in order to fulfil the contract we have
                    with you:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Provide you with access to Cashlet, and to enable your interaction with Cashlet</div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Provide customer service</div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Send you support messages, updates, security alerts, and account notifications</div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />

                      To administer our site and the Service and for internal operations, including troubleshooting,
                      data analysis, testing, research, statistical and survey purposes
                    </div>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    To provide and improve the Cashlet product — we process the information we collect given our
                    legitimate interest in improving the Cashlet Service, and in order to fulfil the contract we have
                    with you:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Detect fraud</div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      To verify your identity, and check it against sanction lists</div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      To keep our platform secure</div>
                  </ul>
                </div>{' '}
              </div>
            </SectionItem>
            <SectionItem
              id="#section5"
              title="Who we share your data with"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_5}
              TheState_String="Section1_5">
              <div className={SectionVisibility.Section1_5 ? 'hidden' : 'visible   '}>
                <div className=" space-y-8 ">
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    <span className="font-semibold">Service providers</span> – In order to fulfil the contract we have
                    with you, we use certain trusted service providers. Here we mean companies that help us provide
                    services you use, and need to process details about you for this reason. We share as little
                    information as we can and encrypt and/or make it impossible for you to be identified by the
                    recipient where possible (for instance by using a User ID rather than your name). Our service
                    providers are highlighted below:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      <span className="font-semibold">Payment Service Provider (M-Pesa)</span>:
                    </div>

                    <div className="space-y-0">
                      M-Pesa provides you with
                      the mobile money transfer service which is necessary to be able to deposit into or withdraw from
                      your Cashlet account. Whenever you are initiate a transfer we share your mobile number with them
                      to enable this to happen.
                    </div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />

                      <span className="font-semibold">KYC Provider (Smile Identity)</span>:
                    </div>
                    <div>
                      Cashlet will share your
                      personal information (names, national ID number or passport number) with Smile Identity in order
                      to verify your identity, and conduct fraud checks, in order to comply with our regulatory
                      obligations.
                    </div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      <span className="font-semibold">Regulated Investment providers</span>:
                    </div>
                    <div>
                      We partner with fund managers
                      to provide you with money market funds that earn you market leading interest on your savings.
                      These fund managers are all regulated by the Capital Markets Authority (CMA) in Kenya. When you make a deposit or withdrawal,
                      Cashlet will share your personal information, with these fund managers in order to fulfil our contract with you,
                      and for the partner fund managers to provide their services to you and to comply with their regulatory obligations.
                    </div>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    <span className="font-semibold">Law enforcement and other government entities</span> - In order to
                    comply with our regulatory and legal obligations, we might share your information with government
                    entities responsible for this.
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Our industry regulator – the Capital Markets Authority (CMA) of Kenya</div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />

                      Authorities that spot and stop financial crime, money laundering, terrorism and tax evasion if the
                      law says we have to, or if it’s necessary for other reasons
                    </div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      The police, courts or dispute resolution bodies if we have to</div>
                  </ul>
                </div>{' '}
              </div>
            </SectionItem>{' '}
            <SectionItem
              id="#section6"
              title="How long we keep your information"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_6}
              TheState_String="Section1_6">
              <div className={SectionVisibility.Section1_6 ? 'hidden' : 'visible space-y-8  '}>
                <div className=" space-y-8 ">
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We keep most of your data as long as you’re using Cashlet. If you no longer want us to use your
                    information you can send a request to{' '}
                    {/* <a className="text-primary-green underline font-semibold" href="malto:support@cashlet.co.ke">
                      support@cashlet.co.ke
                    </a> */}
                    <a
                      className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}</a>{' '}
                    . Please note that if you request erasure of your personal data, we will keep relevant personal
                    information for at least 6 years to comply with the law.
                  </p>
                </div>{' '}
              </div>
            </SectionItem>
            <SectionItem
              id="#section7"
              title="Your rights"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_7}
              TheState_String="Section1_7">
              <div className={SectionVisibility.Section1_7 ? 'hidden' : 'visible space-y-8  '}>
                <div className=" space-y-8 ">
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You have a right to:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Access the personal data we hold about you, or to get a copy of it</div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />

                      Ask for a copy of your personal data in a portable (machine-readable) format or make us send it to
                      someone else
                    </div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Make us correct inaccurate data</div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />

                      Ask us to delete, ‘block’ or suppress your data, though for legal reasons we might not always be
                      able to do it
                    </div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />

                      Say no to us using your data for direct marketing and in certain other ‘legitimate interest’
                      circumstances
                    </div>
                    <div className="flex flex-row relative ">
                      <img
                        src={bulletPoints}
                        className=" object-fit mr-1.5 mt-1.5 h-2.5 w-2.5"
                      />
                      Withdraw any consent you’ve given us</div>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    To do any of these things, please contact us through the Cashlet app or by emailing{' '}
                    {/* <a className="text-primary-green underline font-semibold" href="malto:support@cashlet.co.ke">
                      support@cashlet.co.ke
                    </a> */}
                    <a
                      className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}</a>{' '}
                  </p>
                </div>{' '}
              </div>
            </SectionItem>{' '}
            <SectionItem
              id="#section8"
              title="Where we store your data"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_8}
              TheState_String="Section1_8">
              <div className={SectionVisibility.Section1_8 ? 'hidden' : 'visible space-y-8  '}>
                <div className=" space-y-8 ">
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    All information you provide to us is stored on our secure servers. Any transmission of information
                    to our partners (including information to facilitate payments) are encrypted using TLS technology,
                    the current standard in secure communications over the Internet.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Once we have received your information, we will use strict procedures and security features to try
                    to prevent unauthorised access. Information we deem sensitive are stored using state-of-the-art
                    symmetric encryption (AES).
                  </p>
                </div>{' '}
              </div>
            </SectionItem>
            <SectionItem
              id="#section9"
              title="How to make a complaint"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_9}
              TheState_String="Section1_9">
              <div className={SectionVisibility.Section1_9 ? 'hidden' : 'visible space-y-8  '}>
                <div className=" space-y-8 ">
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If you have a complaint about how we use your personal information, please contact us through the
                    app or send an email to{' '}
                    {/* <a className="text-primary-green underline font-semibold" href="malto:support@cashlet.co.ke">
                      support@cashlet.co.ke
                    </a>{' '} */}
                    <a
                      className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}</a>{' '}
                    and we’ll do our best to fix the problem.
                  </p>
                </div>{' '}
              </div>
            </SectionItem>
            <SectionItem
              id="#section10"
              title="Changes to this policy"
              isMobile={isMobile}
              opactHandl={OpacityHandler}
              TheState={SectionVisibility.Section1_10}
              TheState_String="Section1_10">
              <div className={SectionVisibility.Section1_10 ? 'hidden' : 'visible space-y-8  '}>
                <div className=" space-y-8 ">
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We’ll post any changes we make to our privacy notice on this page and if they’re significant changes
                    we’ll let you know by email.
                  </p>
                </div>{' '}
              </div>
            </SectionItem>
          </section>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicyPage;
