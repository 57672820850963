import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import logo from '../../assets/logo.svg';
import { RegistrationModal } from '../emailModal/emailmodal'

function BlogNavbar(props) {
  let { id } = useParams();

  const [WaitListModal, setWaitListModal] = useState(false)

  function ChangeHandler() {
    setWaitListModal(!WaitListModal)
    // console.log("pressed")
  }


  return (
    <nav
      className={classNames(
        'top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-4 py-1 md:py-4 navbar-expand-lg  ',
        {
          'bg-white': id,
          'bg-primary-gray-light': id === undefined,
        }
      )}
    >
      <div className="container max-w-screen-sm  md:max-w-screen-lg xl:max-w-6xl  2xl:max-w-screen-xl sm:px-4 mx-auto lg:mx-none xl:mx-auto flex items-center justify-between ">
        <RegistrationModal visibility={WaitListModal} handleChange={ChangeHandler} />

        <div className="w-full relative flex lg:justify-between lg:w-auto lg:static md:space-x-2">
          <Link
            to="/"
            className="text-gray-800 font-site leading-relaxed inline-flex items-center sm:mr-4 py-2 whitespace-no-wrap space-x-1 md:space-x-4"
          >
            <img className="h-7 w-auto sm:h-10" src={logo} alt="Logo" />
            <span className=" text-primary-dark text-xs xs:text-sm sm:text-md sm:text-xl md:text-3xl font-bold">Cashlet {id}</span>
          </Link>
          <Link to="/blog" className="inline-flex items-center sm:space-x-4">
            <span className="font-site sm:font-normal text-primary-green-dark text-lg mx-1 xs:mx-2 sm:mx-0 md:text-3xl transform rotate-12 xs:rotate-45">
              |
            </span>
            <span className="sm:font-site font-bold text-primary-green-dark text-xs sm:text-sm  uppercase">blog</span>
          </Link>
        </div>
        <div className="w-full lg:w-auto flex flex-grow items-center bg-transparent shadow-none ">
          <ul className="flex flex-row list-none ml-auto">
            <li className="flex items-center invisible md:visible w-0 md:w-auto">
              <Link
                className="hover:text-gray-600 text-black px-3 lg:px-6 py-4 lg:py-2 flex items-center text-center text-md lg:text-md  font-medium "
                to="/blog"
              >
                All articles
              </Link>
            </li>
            <li className="flex items-center">
              {/* 1_0223 */}
              {/* <div
                onClick={() => { window.location.href = 'https://play.google.com/store/apps/details?id=com.cashlet'}}
                className=" cursor-pointer px-1 sm:px-4 md:px-6 py-2 sm:py-3 sm:mr-3 text-xs sm:text-md font-bold text-white bg-primary-green-dark sm:border-b-4 border-gray-40 rounded-lg sm:rounded-2xl hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
              >
                Join the Waitlist
              </div>  */}

              <div
                onClick={() => { window.location.href = 'https://play.google.com/store/apps/details?id=com.cashlet' }}
                className="flex-column self-center pr-8 bg-primary-green-dark cursor-pointer px-1 sm:px-4 md:px-6 py-2 sm:py-3 sm:mr-3 text-xs sm:text-md font-bold text-white  sm:border-b-4 border-gray-40 rounded-lg sm:rounded-2xl hover:bg-gray-700 focus:outline-none focus:bg-gray-700">
                <div className=" align-text-bottom my-auto font-medium tracking-tight text-xs" >
                  Get it on
                </div>
                <div className=" align-text-bottom my-auto tracking-tight ">
                  Google Play
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default BlogNavbar;
