/*
 * Home page that is view if the user is authenticated of not.
 * pages include: Landing page, about page, terms & conditions page (etc.)
 * */

import React, { useEffect, useRef, useState } from "react";
import { useLocation, Redirect, Route, Switch } from "react-router-dom";
import Banner from "../components/Banner/Banner";
import Footer from "../components/Footer/Footer";
import IndexNavbar from "../components/Navbars/IndexNavbar";
import routes from "../routes/routes";
import { useDispatch } from 'react-redux';
import { setAboutData } from "../_store/_reducers/ranges.reducers";

const MainLayout = () => {
  const mainContent = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  // const [aboutData, setAboutData] = useState({
  //   clientCount: 20000,
  //   fundCount: 8,
  //   totalAmount: 120000000,
  // });

  const getStatistics = async () => {
    try {
      const serverResponse = await fetch(
        `${process.env.REACT_APP_URL}/api/public/investment-growth/investment-detail`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (!serverResponse.ok) {
        throw new Error(`HTTP error! status: ${serverResponse.status}`);
      }
      let jsonResponse;
      try {
        jsonResponse = await serverResponse.json();
        dispatch(setAboutData({aboutData: jsonResponse}))
      } catch (jsonError) {
        dispatch(setAboutData({aboutData: {
          clientCount: 0,
          fundCount: 0,
          totalAmount: 0,
        }}));
      }
    } catch (error) {}
  };

  useEffect(() => {
    getStatistics();
  }, []);

  useEffect(() => {
    document.body.classList.add(
      "font-site",
      "tracking-normal",
      "text-primary-darkest"
    );
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove(
        "leading-normal",
        "tracking-normal",
        "text-gray-900"
      );
    };
  });

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  function getRoutes(routes) {
    return routes.map((prop, key) => {
      if (prop.layout === "/main-layout") {
        return (
          <Route exact path={prop.path} component={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  }

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <IndexNavbar fixed />
        {/* Page content */}
        <div className="mt-24">
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/" to="/" />
        </Switch>
        </div>

        {/* {location.pathname !== "/ios-coming-soon" ? <Banner /> : null} */}
        <Footer />
      </div>
    </>
  );
};
export default MainLayout;
