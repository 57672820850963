import React from 'react'
import StoresIcon from "../../assets/img/googleplaystore.png";
import AppStoreIcon from "../../assets/img/appstore.png";

function SocialMediaIcons(props) {
    return (
        <div>
            <div className="flex flex-row pb-5 cursor-pointer gap-4 items-center justify-center sm:mx-auto lg:ml-0">
                <div
                    onClick={() => {
                        window.location.href =
                            "https://www.clkmg.com/Cashletapp/pswebsite";
                    }}
                    style={{ width: '144px', alignItems: 'center', height: '44px', borderRadius: '5px', borderColor: props?.borderWhite ? 'border-white' : 'border-black' }}
                    className="flex px-2 justify-between shadow-lg overflow-hidden border text-black font-bold bg-black"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30" fill="none">
                        <g filter="url(#filter0_ii_8200_15964)">
                            <path d="M1.48 1.29124C1.1599 1.63004 0.970703 2.15584 0.970703 2.83674V27.1643C0.970703 27.8463 1.1599 28.371 1.48 28.7098L1.5614 28.789L15.1893 15.1611V15.0005V14.8399L1.5614 1.21094L1.48 1.29124Z" fill="url(#paint0_linear_8200_15964)" />
                            <path d="M19.7314 19.7052L15.1895 15.1611V15.0005V14.8399L19.7325 10.2969L19.8348 10.3552L25.2171 13.4132C26.7538 14.2866 26.7538 15.7155 25.2171 16.59L19.8348 19.648L19.7314 19.7052Z" fill="url(#paint1_linear_8200_15964)" />
                            <g filter="url(#filter1_i_8200_15964)">
                                <path d="M19.8351 19.6464L15.1887 15L1.48047 28.7093C1.98647 29.2461 2.82357 29.3121 3.76627 28.7775L19.8351 19.6464Z" fill="url(#paint2_linear_8200_15964)" />
                            </g>
                            <path d="M19.8351 10.3552L3.76627 1.22515C2.82357 0.689451 1.98647 0.756552 1.48047 1.29335L15.1898 15.0027L19.8351 10.3552Z" fill="url(#paint3_linear_8200_15964)" />
                        </g>
                        <defs>
                            <filter id="filter0_ii_8200_15964" x="0.970703" y="0.855469" width="25.3984" height="28.293" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="-0.15" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8200_15964" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="0.15" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="effect1_innerShadow_8200_15964" result="effect2_innerShadow_8200_15964" />
                            </filter>
                            <filter id="filter1_i_8200_15964" x="1.48047" y="15" width="18.3545" height="14.1484" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="-0.15" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8200_15964" />
                            </filter>
                            <linearGradient id="paint0_linear_8200_15964" x1="13.9813" y1="2.57934" x2="-4.47891" y2="21.0395" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#00A0FF" />
                                <stop offset="0.0066" stopColor="#00A1FF" />
                                <stop offset="0.2601" stopColor="#00BEFF" />
                                <stop offset="0.5122" stopColor="#00D2FF" />
                                <stop offset="0.7604" stopColor="#00DFFF" />
                                <stop offset="1" stopColor="#00E3FF" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_8200_15964" x1="27.2174" y1="15.0005" x2="0.601914" y2="15.0005" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FFE000" />
                                <stop offset="0.4087" stopColor="#FFBD00" />
                                <stop offset="0.7754" stopColor="#FFA500" />
                                <stop offset="1" stopColor="#FF9C00" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_8200_15964" x1="17.3118" y1="17.5233" x2="-7.72256" y2="42.5576" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FF3A44" />
                                <stop offset="1" stopColor="#C31162" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_8200_15964" x1="-1.97199" y1="-6.80507" x2="9.20654" y2="4.37346" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#32A071" />
                                <stop offset="0.0685" stopColor="#2DA771" />
                                <stop offset="0.4762" stopColor="#15CF74" />
                                <stop offset="0.8009" stopColor="#06E775" />
                                <stop offset="1" stopColor="#00F076" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div className="flex flex-col justify-center text-white my-auto h-2/3" style={{ alignItems: 'flex-start', height: 'object-fit' }}>
                        <div className="font-medium tracking-tight" style={{ fontSize: '10px' }}>
                            GET IT ON
                        </div>
                        <div className="tracking-tight font-icon leading-4">
                            Google Play
                        </div>
                    </div>
                </div>

                <div
                    onClick={() => {
                        window.open(
                            "https://apps.apple.com/us/app/cashlet-savings-investments/id6463010865",
                            "_blank"
                        );
                    }}
                    className="h-full flex flex-row"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="132" height="44" viewBox="0 0 132 44" fill="none">
                        <g filter="url(#filter0_i_5426_15411)">
                            <rect width="132" height="44" rx="7" fill="black" />
                            <rect x="0.5" y="0.5" width="131" height="43" rx="6.5" stroke="#A6A6A6" />
                            <path d="M89.6784 21.1204V23.6405H88.0987V25.2932H89.6784V30.9087C89.6784 32.8262 90.5458 33.5932 92.7281 33.5932C93.1116 33.5932 93.4768 33.5475 93.7964 33.4927V31.8583C93.5224 31.8857 93.349 31.904 93.0476 31.904C92.0706 31.904 91.6415 31.4474 91.6415 30.4065V25.2932H93.7964V23.6405H91.6415V21.1204H89.6784Z" fill="#FFFFFF" />
                            <path d="M99.3556 33.7301C102.259 33.7301 104.04 31.7853 104.04 28.5621C104.04 25.3571 102.25 23.4031 99.3556 23.4031C96.452 23.4031 94.6623 25.3571 94.6623 28.5621C94.6623 31.7853 96.4429 33.7301 99.3556 33.7301ZM99.3556 31.9861C97.6481 31.9861 96.6894 30.7352 96.6894 28.5621C96.6894 26.4072 97.6481 25.1471 99.3556 25.1471C101.054 25.1471 102.022 26.4072 102.022 28.5621C102.022 30.7261 101.054 31.9861 99.3556 31.9861Z" fill="#FFFFFF" />
                            <path d="M105.563 33.5384H107.526V27.6672C107.526 26.2702 108.576 25.3297 110.065 25.3297C110.412 25.3297 110.996 25.3937 111.16 25.4484V23.5127C110.95 23.4579 110.576 23.4305 110.284 23.4305C108.987 23.4305 107.882 24.1427 107.599 25.1197H107.453V23.5949H105.563V33.5384Z" fill="#FFFFFF" />
                            <path d="M116.035 25.0741C117.487 25.0741 118.436 26.0876 118.482 27.649H113.46C113.569 26.0967 114.583 25.0741 116.035 25.0741ZM118.473 30.8539C118.107 31.63 117.295 32.0592 116.108 32.0592C114.537 32.0592 113.524 30.9543 113.46 29.2103V29.1008H120.481V28.416C120.481 25.2932 118.811 23.4031 116.044 23.4031C113.241 23.4031 111.46 25.421 111.46 28.5986C111.46 31.7761 113.204 33.7301 116.053 33.7301C118.327 33.7301 119.915 32.6344 120.363 30.8539H118.473Z" fill="#FFFFFF" />
                            <path d="M76.8044 29.8664C76.9558 32.3081 78.9906 33.8696 82.019 33.8696C85.2556 33.8696 87.2809 32.2324 87.2809 29.6203C87.2809 27.5667 86.1263 26.431 83.325 25.778L81.8203 25.4089C80.041 24.9925 79.3218 24.4341 79.3218 23.4594C79.3218 22.2291 80.4385 21.4246 82.1136 21.4246C83.7036 21.4246 84.8014 22.2101 85.0001 23.4688H87.0632C86.9402 21.1691 84.9149 19.5508 82.142 19.5508C79.1609 19.5508 77.1735 21.1691 77.1735 23.6013C77.1735 25.6077 78.2997 26.8001 80.7698 27.3774L82.53 27.8033C84.3376 28.2291 85.1326 28.8538 85.1326 29.8948C85.1326 31.1062 83.8834 31.9863 82.1799 31.9863C80.3534 31.9863 79.0852 31.1629 78.9054 29.8664H76.8044Z" fill="#FFFFFF" />
                            <path d="M56.4683 23.4305C55.117 23.4305 53.9482 24.1062 53.3456 25.2384H53.1995V23.5949H51.3094V36.8437H53.2725V32.0318H53.4278C53.9482 33.0818 55.0713 33.7027 56.4866 33.7027C58.9976 33.7027 60.5955 31.7213 60.5955 28.5621C60.5955 25.4028 58.9976 23.4305 56.4683 23.4305ZM55.9114 31.9405C54.2678 31.9405 53.236 30.6439 53.236 28.5712C53.236 26.4894 54.2678 25.1928 55.9205 25.1928C57.5823 25.1928 58.5776 26.462 58.5776 28.5621C58.5776 30.6713 57.5823 31.9405 55.9114 31.9405Z" fill="#FFFFFF" />
                            <path d="M67.4648 23.4305C66.1134 23.4305 64.9447 24.1062 64.3421 25.2384H64.196V23.5949H62.3059V36.8437H64.269V32.0318H64.4242C64.9447 33.0818 66.0678 33.7027 67.4831 33.7027C69.9941 33.7027 71.592 31.7213 71.592 28.5621C71.592 25.4028 69.9941 23.4305 67.4648 23.4305ZM66.9078 31.9405C65.2643 31.9405 64.2325 30.6439 64.2325 28.5712C64.2325 26.4894 65.2643 25.1928 66.917 25.1928C68.5788 25.1928 69.574 26.462 69.574 28.5621C69.574 30.6713 68.5788 31.9405 66.9078 31.9405Z" fill="#FFFFFF" />
                            <path d="M47.7872 33.5384H50.0396L45.1089 19.882H42.8281L37.8975 33.5384H40.0742L41.3328 29.9137H46.538L47.7872 33.5384ZM43.8597 22.3615H44.0206L45.9985 28.1345H41.8723L43.8597 22.3615Z" fill="#FFFFFF" />
                            <path d="M39.2158 9.58196V16.1699H41.5944C43.5575 16.1699 44.6943 14.96 44.6943 12.8554C44.6943 10.7827 43.5484 9.58196 41.5944 9.58196H39.2158ZM40.2385 10.5133H41.4803C42.8453 10.5133 43.6534 11.3807 43.6534 12.8691C43.6534 14.3802 42.859 15.2385 41.4803 15.2385H40.2385V10.5133Z" fill="#FFFFFF" />
                            <path d="M48.1759 16.2657C49.6277 16.2657 50.518 15.2933 50.518 13.6817C50.518 12.0792 49.6232 11.1022 48.1759 11.1022C46.7241 11.1022 45.8293 12.0792 45.8293 13.6817C45.8293 15.2933 46.7196 16.2657 48.1759 16.2657ZM48.1759 15.3937C47.3222 15.3937 46.8428 14.7683 46.8428 13.6817C46.8428 12.6043 47.3222 11.9742 48.1759 11.9742C49.0251 11.9742 49.509 12.6043 49.509 13.6817C49.509 14.7637 49.0251 15.3937 48.1759 15.3937Z" fill="#FFFFFF" />
                            <path d="M58.0994 11.1981H57.1178L56.2321 14.992H56.1545L55.1318 11.1981H54.1913L53.1687 14.992H53.0956L52.2054 11.1981H51.2101L52.5797 16.1699H53.5887L54.6114 12.5084H54.689L55.7162 16.1699H56.7343L58.0994 11.1981Z" fill="#FFFFFF" />
                            <path d="M59.2298 16.1699H60.2113V13.2617C60.2113 12.4856 60.6724 12.0016 61.3983 12.0016C62.1242 12.0016 62.4712 12.3988 62.4712 13.1978V16.1699H63.4528V12.9512C63.4528 11.7688 62.841 11.1022 61.7316 11.1022C60.9829 11.1022 60.4898 11.4355 60.2478 11.9879H60.1748V11.1981H59.2298V16.1699Z" fill="#FFFFFF" />
                            <path d="M64.9986 16.1699H65.9802V9.25781H64.9986V16.1699Z" fill="#FFFFFF" />
                            <path d="M69.6718 16.2657C71.1236 16.2657 72.0139 15.2933 72.0139 13.6817C72.0139 12.0792 71.119 11.1022 69.6718 11.1022C68.22 11.1022 67.3252 12.0792 67.3252 13.6817C67.3252 15.2933 68.2154 16.2657 69.6718 16.2657ZM69.6718 15.3937C68.8181 15.3937 68.3387 14.7683 68.3387 13.6817C68.3387 12.6043 68.8181 11.9742 69.6718 11.9742C70.521 11.9742 71.0049 12.6043 71.0049 13.6817C71.0049 14.7637 70.521 15.3937 69.6718 15.3937Z" fill="#FFFFFF" />
                            <path d="M74.9385 15.4257C74.4043 15.4257 74.0163 15.1655 74.0163 14.7181C74.0163 14.2798 74.3267 14.0469 75.0115 14.0013L76.2259 13.9237V14.3391C76.2259 14.9555 75.6781 15.4257 74.9385 15.4257ZM74.6874 16.2521C75.3402 16.2521 75.8835 15.969 76.1803 15.4714H76.2579V16.1699H77.2029V12.7732C77.2029 11.7231 76.4999 11.1022 75.2535 11.1022C74.1258 11.1022 73.3223 11.6501 73.2219 12.5038H74.1715C74.2811 12.1523 74.66 11.9514 75.2078 11.9514C75.879 11.9514 76.2259 12.2482 76.2259 12.7732V13.2023L74.8791 13.28C73.6967 13.353 73.0301 13.8689 73.0301 14.7637C73.0301 15.6722 73.7286 16.2521 74.6874 16.2521Z" fill="#FFFFFF" />
                            <path d="M80.5339 16.2521C81.2187 16.2521 81.7985 15.9279 82.0953 15.3846H82.1729V16.1699H83.1133V9.25781H82.1318V11.9879H82.0587C81.7894 11.4401 81.2141 11.1159 80.5339 11.1159C79.2784 11.1159 78.4703 12.1112 78.4703 13.6817C78.4703 15.2568 79.2692 16.2521 80.5339 16.2521ZM80.8124 11.9971C81.6341 11.9971 82.15 12.6499 82.15 13.6863C82.15 14.7272 81.6387 15.3709 80.8124 15.3709C79.9815 15.3709 79.4838 14.7363 79.4838 13.6817C79.4838 12.6362 79.986 11.9971 80.8124 11.9971Z" fill="#FFFFFF" />
                            <path d="M89.4785 16.2657C90.9303 16.2657 91.8205 15.2933 91.8205 13.6817C91.8205 12.0792 90.9257 11.1022 89.4785 11.1022C88.0267 11.1022 87.1318 12.0792 87.1318 13.6817C87.1318 15.2933 88.0221 16.2657 89.4785 16.2657ZM89.4785 15.3937C88.6247 15.3937 88.1454 14.7683 88.1454 13.6817C88.1454 12.6043 88.6247 11.9742 89.4785 11.9742C90.3276 11.9742 90.8116 12.6043 90.8116 13.6817C90.8116 14.7637 90.3276 15.3937 89.4785 15.3937Z" fill="#FFFFFF" />
                            <path d="M93.1199 16.1699H94.1014V13.2617C94.1014 12.4856 94.5625 12.0016 95.2884 12.0016C96.0143 12.0016 96.3613 12.3988 96.3613 13.1978V16.1699H97.3429V12.9512C97.3429 11.7688 96.7311 11.1022 95.6217 11.1022C94.873 11.1022 94.3799 11.4355 94.1379 11.9879H94.0649V11.1981H93.1199V16.1699Z" fill="#FFFFFF" />
                            <path d="M101.864 9.96089V11.2209H101.074V12.0473H101.864V14.855C101.864 15.8138 102.297 16.1973 103.388 16.1973C103.58 16.1973 103.763 16.1744 103.923 16.147V15.3298C103.786 15.3435 103.699 15.3527 103.548 15.3527C103.06 15.3527 102.845 15.1244 102.845 14.6039V12.0473H103.923V11.2209H102.845V9.96089H101.864Z" fill="#FFFFFF" />
                            <path d="M105.24 16.1699H106.222V13.2663C106.222 12.513 106.669 12.0062 107.473 12.0062C108.167 12.0062 108.536 12.408 108.536 13.2023V16.1699H109.518V12.9604C109.518 11.7779 108.865 11.1068 107.806 11.1068C107.057 11.1068 106.532 11.4401 106.29 11.9971H106.213V9.25781H105.24V16.1699Z" fill="#FFFFFF" />
                            <path d="M113.059 11.9377C113.785 11.9377 114.26 12.4445 114.282 13.2252H111.771C111.826 12.449 112.333 11.9377 113.059 11.9377ZM114.278 14.8276C114.095 15.2157 113.689 15.4303 113.095 15.4303C112.31 15.4303 111.803 14.8779 111.771 14.0059V13.9511H115.282V13.6087C115.282 12.0473 114.447 11.1022 113.063 11.1022C111.662 11.1022 110.772 12.1112 110.772 13.7C110.772 15.2887 111.644 16.2657 113.068 16.2657C114.205 16.2657 114.999 15.7179 115.223 14.8276H114.278Z" fill="#FFFFFF" />
                            <path d="M27.2458 22.3321C27.2696 20.483 28.2627 18.7334 29.838 17.7649C28.8442 16.3455 27.1796 15.4456 25.4477 15.3914C23.6006 15.1975 21.8098 16.4967 20.8685 16.4967C19.909 16.4967 18.4598 15.4107 16.8992 15.4428C14.865 15.5085 12.9687 16.665 11.979 18.4434C9.85158 22.1267 11.4384 27.5399 13.4763 30.5171C14.4959 31.975 15.6875 33.6035 17.2467 33.5458C18.7725 33.4825 19.3423 32.5728 21.184 32.5728C23.0087 32.5728 23.5433 33.5458 25.1341 33.509C26.7714 33.4825 27.8029 32.0447 28.7867 30.573C29.5193 29.5342 30.083 28.3861 30.457 27.1712C28.5329 26.3575 27.248 24.4212 27.2458 22.3321Z" fill="#FFFFFF" />
                            <path d="M24.241 13.4334C25.1336 12.3618 25.5734 10.9844 25.4669 9.59375C24.1031 9.73699 22.8433 10.3888 21.9386 11.4193C21.0539 12.4262 20.5935 13.7793 20.6805 15.1168C22.0448 15.1309 23.386 14.4968 24.241 13.4334Z" fill="#FFFFFF" />
                        </g>
                        <defs>
                            <filter id="filter0_i_5426_15411" x="0" y="0" width="132" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="6" />
                                <feGaussianBlur stdDeviation="4" />
                                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.16 0" />
                                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5426_15411" />
                            </filter>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>

    )
}

export default SocialMediaIcons