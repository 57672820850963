const questions = {
    1: [
        {
            id: 1,
            title: "What is Cashlet?",
            description:
                <div>
                    <p>
                        Cashlet is a savings and investment super-app that provides you with easy and convenient tools to manage, grow, and monitor your money to help you achieve your financial goals. You go through one simple and fully digital process to sign up and gain access to the largest variety of investment savings and investment products in Kenya. We currently have 8 money market funds for you to choose from on the platform, with more to come.
                    </p>
                    <br />
                    <br />
                    <p>
                        Once onboarded, you are supported by smart tools such as lock goals, payday savings, automatic deposits, and group savings to save, invest, and grow your funds.
                    </p>
                    <br />
                    <br />
                    <p>
                        Cashlet is owned by Sycamore Capital Kenya Limited and is regulated by
                        the <strong> Capital Market Authority (CMA) </strong>
                    </p>
                </div>
        },
        {
            id: 2,
            title: "Why should I save on Cashlet?",
            description:
                <div>
                    <p>
                        Saving on Cashlet offers you a smart and flexible way to grow your money while reaching your financial goals. With Cashlet, you get access to some of the best savings and investment products in Kenya, including regulated money market funds, all from the convenience of one app. You can enjoy competitive interest rates of up to 16% per year, significantly higher than traditional savings accounts. Plus, Cashlet provides helpful tools like automatic savings, lock goals, and group savings, allowing you to save with discipline and track your progress in real-time.
                    </p>
                    <br />
                    <br />
                    <p>
                        Your funds are safe, as Cashlet is regulated by the Capital Markets Authority (CMA), and you have the flexibility to withdraw your money whenever you need it, directly to your M-Pesa or bank account. With Cashlet, your savings work harder for you, helping you achieve your financial milestones faster.
                    </p>
                    <br />
                    <br />
                    <p>Cashlet is suitable for anyone:</p>
                    <div className="ml-4">
                        <ul className="list-disc">
                            <li>
                                Looking to earn competitive interest of up to 16% per annum on your savings, helping your money grow faster compared to traditional savings accounts.
                            </li>
                            <li>
                                Looking to manage his/her money conveniently on a mobile app and hates the old way of saving and investing involving lots of paperwork, queues, phone/email exchanges, delays, and unresponsive customer support.
                            </li>
                            <li>Who wants to save towards specific goals (e.g., buying a car, vacation, or emergency fund) using tools like lock goals and automatic savings.
                            </li>
                            <li>Who prioritizes the safety of their money and data, backed by regulation from the Capital Markets Authority (CMA) and bank-level encryption.</li>
                        </ul>
                    </div>
                </div>
        },
        {
            id: 3,
            title: "How do I start saving and investing on Cashlet?",
            description:
                <div
                >
                    <p>
                        Signing up is easy, free and carries no obligations. To
                        start saving and investing on Cashlet:
                    </p>
                    <div className="ml-4">
                        <ul className="list-disc ">
                            <li>
                                Download the app from the Google PlayStore and Apple
                                AppStore.
                            </li>
                            <li>Set up and create an account with your personal details.
                            </li>
                            <li>Verify your identity using your ID/Passport/alien card so we know you are who you say you are.
                            </li>
                            <li>
                                Enter your M-Pesa number and then make your first
                                deposit.
                            </li>
                            <li>
                                You can make a direct deposit from the app or do a Paybill/ bank transfer to your Cashlet goal account.
                            </li>
                        </ul>
                    </div>
                </div>
        },
        {
            id: 4,
            title: "Can I get a loan on Cashlet?",
            description:
                <div>
                    <p>
                        No, we do not give loans. Cashlet is an app designed to help you save and invest your money with ease. It allows you to set aside funds periodically (Daily, Weekly, or Monthly) towards a specific goal or lock away funds for a set period of time.
                    </p>
                </div>
        },
        {
            id: 5,
            title: "Is Cashlet regulated? How do I know this is not a scam?",
            description:
                <div>
                    <p>
                        Yes. Cashlet is regulated by the Capital Market Authority
                        (CMA) of Kenya. You can look this up or find further
                        information directly on the{" "}
                        <a
                            href="https://www.cma.or.ke/cma-graduates-sycamore-capital-limited-from-the-regulatory-sandbox/"
                            target="_blank"
                            rel="noreferrer"
                            className="faq-green underline font-semibold"
                        >
                            CMA’s website.
                        </a>
                    </p>
                    <br />
                    <br />
                    <p>We only work with fund managers such as (ICEA Lion, Etica Capital, Nabo Capital, Orient Asset Managers etc) which are also licensed and regulated by the CMA.</p>
                    <br />
                    <br /><p>Additionally, Cashlet employs bank-level encryption to safeguard your money, ensuring only you have access to your data, which is never stored or used without your consent.</p>
                </div>
        },
        {
            id: 6,
            title: "What mobile platforms does Cashlet support?",
            description:
                <div>
                    <p>
                        The Cashlet app is available for free in the Google Play Store and Apple App Store. We’re continually working on the app, and we release regular updates. If you’re looking for a particular feature or have feedback or suggestions for the app, we would love to hear it.
                    </p>
                </div>
        },
        {
            id: 7,
            title: "Is Cashlet for Kenyans only or can non-Kenyans too open an account with you?",
            description:
                <div>
                    <p>
                        Yes, people in the diaspora can use Cashlet but as long as you have a Kenyan phone number to receive an OTP you can register and log into your account.
                    </p>
                </div>
        },
    ],
    2: [
        {
            id: 1,
            title: "How safe is investing my money on Cashlet?",
            description:
                <div>
                    <p>
                        We take security and privacy very seriously at Cashlet and it is our top priority. All your financial information is secured with bank-level encryption technology of the highest standards.
                    </p>
                    <br />
                    <br />
                    <p>
                        We operate in partnership with 4 established fund managers in Kenya – ICEA Lion Asset Management, Etica Capital, Nabo Capital and Orient Asset Managers. Based on your choice, your funds are placed and warehoused in the money market funds managed by one of these fund managers. These money market funds are all approved, monitored and regulated by the Capital Markets Authority (CMA) of Kenya so your funds are under secure watch.
                    </p>
                    <br />
                    <br />
                    <p>So Cashlet does not hold your funds. When you make a deposit, the funds move directly from your M-Pesa/bank account into the collection bank account of these partner fund managers at Stanbic Bank, or Equity Bank.</p>
                </div>
        },
        {
            id: 2,
            title: "What is the passcode?",
            description:
                <div>
                    <p>
                        This is a 4-digit unique passcode that you set up when creating a Cashlet account. It is used to secure your account and this is what you will use for logging in to your account, and initiating transfers and withdrawals on your Cashlet account.
                    </p>
                    <br />
                    <br />
                    <p>In addition to the 4-digit passcode, you can also choose to secure your account by adding biometrics such as your fingerprint as part of your log in options.
                    </p>
                </div>
        },
        {
            id: 3,
            title: "Does Cashlet have an office?",
            description:
                <div>
                    <p>
                        Yes! We are located on 4th Floor, Laiboni Center, Lenana Road, Kilimani, Nairobi. Feel free to pop-in anytime to speak and connect with our team.
                    </p>
                    <br />
                    <br />
                    <p>
                        Contact Number:{" "}
                        <span className="font-semibold">0112029738 </span> <br />
                        Email:{" "}
                        <a
                            className="faq-green underline font-semibold"
                            href={`mailto:team@cashlet.co.ke?subject=${"Question about Cashlet"}&body=${""}`}
                        >
                            team@cashlet.co.ke{" "}
                        </a>
                    </p>
                </div>
        }
    ],
    3: [
        {
            id: 1,
            title: "What is account activation?",
            description:
                <div>
                    <p>
                        Account activation is a simple process that helps us verify that you are who you say you are, and to add extra security to your account. It is quick and takes no more than 2 minutes to complete.
                    </p>
                    <br />
                    <br />
                    <p>
                        We do this for business reasons and to comply with relevant government requirements. It allows us to process new users efficiently, and to ensure that we eliminate fraudulent applications or those that pose a risk of money laundering.
                    </p>
                </div>
        },
        {
            id: 2,
            title: "What information do I need to submit to activate my account?",
            description:
                <div>
                    <p>
                        There are 2 things you need to submit as part of the account activation process:
                    </p>
                    <div className="ml-4">
                        <ul className="list-disc ">
                            <li>
                                ID or Passport number.
                            </li>
                            <li>
                                Photo of your National ID, Alien card or Passport: This helps us to verify that the information that you provide about yourself are correct and that you are real.
                            </li>
                        </ul>
                    </div>
                    <br />
                    <p>Here is a video from our Youtube page to guide you on how to activate your account: <a
                        href="https://youtu.be/Dx6eiMuAIQ4?si=nyp1C5TuRagfsMA4"
                        target="_blank"
                        rel="noreferrer"
                        className="faq-green underline font-semibold"
                    >
                        https://youtu.be/Dx6eiMuAIQ4?si=nyp1C5TuRagfsMA4
                    </a></p>
                </div>
        },
        {
            id: 3,
            title: "How do I submit the information?",
            description:
                <div>
                    <p>
                        All the information is submitted via the Cashlet app. It is a simple process that does not take more than 2 minutes.
                    </p>
                    <br />
                    <br />
                    <p>All of this information is saved securely using bank-level encryption and in line with the requirements of the Data Protection Act.
                    </p>
                </div>
        },
    ],
    4: [
        {
            id: 1,
            title: "What are the deposit charges on the app?",
            description:
                <div>
                    <p>
                        Cashlet does not charge any fees for deposits. However, the normal transaction charges from MPesa or bank transfer apply.
                    </p>
                </div>
        },
        {
            id: 2,
            title: "How do I get the Paybill/Bank transfer details on the app and make a deposit?",
            description:
                <div>
                    <p>
                        Click on the goal account you would like to deposit the funds into then on “Transfer Details” icon right below the deposit and withdraw buttons to get the account details. Copy these account details to your clipboard, make the transfer then forward the confirmation message to <span
                            className="faq-green underline font-semibold"
                        >
                            transfers@cashlet.co.ke
                        </span> for processing.
                    </p>
                    <br />
                    <br />
                    <p>Deposits made via Paybill/bank transfer do not reflect automatically on the app after deposit. You have to send the confirmation message to the transfers email after deposit for processing of the amount to your account in 2 working days.</p>
                    <br />
                    <br />
                    <p>Here is a video from our Youtube page to guide you on how to make the deposit: <a
                        href="https://youtu.be/M8NHCbRL-sc?si=sZ_zNFcfl6ar5r-k"
                        target="_blank"
                        rel="noreferrer"
                        className="faq-green underline font-semibold"
                    >
                        https://youtu.be/M8NHCbRL-sc?si=sZ_zNFcfl6ar5r-k
                    </a></p>
                </div>
        },
        {
            id: 3,
            title: "Is there a maximum limit to how much I can deposit?",
            description:
                <div>
                    <p>
                        There is no maximum limit to how much one can deposit to their Cashlet account.
                    </p>
                    <br />
                    <br />
                    <p>
                        However, for Mpesa deposits, there is a KSh 250,000 daily deposit limit. There is no limit for bank deposits.
                    </p>
                </div>
        },
        {
            id: 4,
            title: "How long do Deposits take to reflect in my account?",
            description:
                <div>
                    <p>
                        Deposits take 2 working days excluding weekends and public holidays to be credited and become available in your account – to start earning interest and also make a withdrawal from the account.
                    </p>
                </div>
        },
        {
            id: 5,
            title: "Why does it take so long to reflect and not immediately?",
            description:
                <div>
                    <p>
                        This is because of the processing time it takes between both Mpesa and the Fund Manager.
                    </p>
                </div>
        },
        {
            id: 6,
            title: "Why is there a difference between the Total Funds and Available Balance on the app?",
            description:
                <div>
                    <p>
                        The difference is typically because of recent deposits and interests earned in the month. When you make a deposit, it is immediately added to your total balance but it only gets added to your available balance after 2 working days. Also, interest is calculated every day and credited to your total balance but it only gets added to available balance at the end to the month.
                    </p>
                </div>
        },
        {
            id: 7,
            title: "Can I add another number to my Cashlet account for deposits?",
            description:
                <div>
                    <p>
                        Yes, this is possible. Follow these steps for that:
                    </p>
                    <div className="ml-4">
                        <ol>
                            <li>
                                1. Click on the goal account you would like to deposit into
                            </li>
                            <li>
                                2. Click on Deposit then add the amount you want to deposit</li>
                            <li>3. On the next page, right below your existing phone number, tap on Add another mobile money account and add the new contact then save</li>
                        </ol>
                    </div>
                    <br />
                    <p>Note: For security reasons, you can only add a different contact for deposits. Withdrawals can only be made to the phone number you used to sign up.</p>
                </div>
        },
        {
            id: 8,
            title: "How do I transfer funds from one of my goals to another?",
            description:
                <div>
                    <p>
                        To transfer from one goal to another:
                    </p>
                    <div className="ml-4">
                        <ol>
                            <li>
                                1. Select "Transfers" on the bottom of the home page
                            </li>
                            <li>
                                2. Select "Goal transfers"</li>
                            <li>3. Go through the process on the app and confirm the transfer</li>
                        </ol>
                    </div>
                    <br />
                    <p>Note: You can only move funds saved in the same Fund Manager ie Kasha to Kasha goal or Etica to Etica goal. Here is a video to guide you on how to make the goal transfers: <a
                        href="https://youtu.be/8ezHmBIgMzQ?si=LURP3NA07vg-5v-v"
                        target="_blank"
                        rel="noreferrer"
                        className="faq-green underline font-semibold"
                    >
                        https://youtu.be/8ezHmBIgMzQ?si=LURP3NA07vg-5v-v
                    </a>
                    </p>
                </div>
        },
    ],
    5: [
        {
            id: 1,
            title: "What are the withdrawal charges on the app?",
            description:
                <div>
                    <p>
                        Bank withdrawals are free but Mpesa withdrawals have some transaction charges. Such as:
                    </p>
                    <div className="border border-gray-600 my-4">
                        <table className="w-full table-auto divide-y divide-gray-600 text-left">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >
                                    </th>
                                    <th
                                        scope="col"
                                        colSpan={2}
                                        className="px-2 py-3 font-bold text-black tracking-wider text-center"
                                    >
                                        Transaction Fees (KES)
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-t border-gray-600 font-bold text-black tracking-wider"
                                    >
                                        Withdrawal Amount (KES)
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-t border-gray-600 font-bold text-black tracking-wider"
                                    >
                                        ICEA Lion
                                    </th>
                                    <th
                                        scope="col"
                                        colSpan={2}
                                        className="px-2 py-3 font-bold border-t text-black border-gray-600 tracking-wider"
                                    >
                                        Others
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-600">
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        0-100
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">0</td>
                                    <td className="px-2 py-3">4</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        101-200
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">5</td>
                                    <td className="px-2 py-3">8</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        201-500
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">5</td>
                                    <td className="px-2 py-3">10</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        501-5,000
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">11</td>
                                    <td className="px-2 py-3">20</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        5,001-20,000
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">11</td>
                                    <td className="px-2 py-3">25</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        20,001-150,000
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">13</td>
                                    <td className="px-2 py-3">30</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        },
        {
            id: 2,
            title: "How long do withdrawals take?",
            description:
                <div>
                    <p>
                        Mpesa withdrawals are credited within 1 working day excluding weekends and public holidays and have a daily withdrawal limit of KSh 20,000. Also, you are allowed to make only 1 withdrawal to Mpesa per day.
                    </p>
                    <br />
                    <br />
                    <p>Bank withdrawals are credited to your account within 2-3 working days excluding weekends and public holidays and have a withdrawal limit of KSh 250,000 per day.</p>
                </div>
        },
        {
            id: 3,
            title: "Can I withdraw the interest I have gained?",
            description:
                <div>
                    <p>
                        Interest is calculated every day and credited to your total balance but it only gets added to available balance at the end to the month, meaning it only becomes available for withdrawal on the 2<sup>nd</sup> day of each new month.
                    </p>
                </div>
        },
        {
            id: 4,
            title: "When can I make a withdrawal from my account?",
            description:
                <div>
                    <p>
                        When you make a deposit to your account, it takes 2 working days excluding weekends and public holidays to be credited to your account and become available for withdrawal. Once the deposited funds reflect in your account, you can make a withdrawal anytime unless you had locked your goal account. </p>
                </div>
        },
        {
            id: 5,
            title: "Can I make a withdrawal to another phone number?",
            description:
                <div>
                    <p>
                        No, this is not possible. We do this to safeguard your funds by allowing you to withdraw only to the phone number you used to sign up to the app therefore no unauthorized persons can have access to your funds. </p>
                </div>
        },
        {
            id: 6,
            title: "How do I withdraw into my M-Pesa account?",
            description:
                <div>
                    <p>
                        Select “Transfers” on the menu tab at the bottom of the home screen and then click on the "Withdraw Money" option. Select the savings goal that you want to withdraw from. Select your M-Pesa account the funds will be transferred into and then confirm the amount you want to withdraw. Your funds will be transferred to your M-Pesa within 1 working day. Please note that there is a maximum withdrawal limit of KES 20,000 per day.</p>

                    <br />
                    <br /> <p>For security purposes, you can only withdraw into the M-pesa account that you used to set up your Cashlet account.</p>
                    <br />
                    <br /><p>How to withdraw to Mpesa from your Cashlet account: <a
                        href="https://youtu.be/Dxp1FaGCoTI?si=3qD0Fh_rkkOJp0Bv"
                        target="_blank"
                        rel="noreferrer"
                        className="faq-green underline font-semibold"
                    >
                        https://youtu.be/Dxp1FaGCoTI?si=3qD0Fh_rkkOJp0Bv
                    </a></p>
                </div>
        },
        {
            id: 7,
            title: "How do I withdraw into my Bank account?",
            description:
                <div>
                    <p>
                        Select “Transfers” on the menu tab at the bottom of the home screen and then click on the "Withdraw Money" option. Select the savings goal that you want to withdraw from. Click on the goal account you would like to withdraw from, click the withdraw button and add the amount you would like to withdraw. Select the bank option and your Bank account you would like the funds withdrawn into, select the bank branch and add your account number.</p>
                    <br />
                    <br /><p>
                        If it’s a first time making a bank withdrawal from your Cashlet account, you will need to submit one of the following documents then save:
                    </p>
                    <div className="ml-4">
                        <ol>
                            <li>
                                1. First page of latest bank statement
                            </li>
                            <li>
                                2. Front part of your debit card</li>
                            <li>3. Cancelled cheque or </li>
                            <li>4. A confirmation letter from your bank, any other document is not accepted as proof of account</li>
                        </ol>
                    </div>
                    <br /><p>We request these details for security reasons to prove that you are the owner of the bank account. Your funds will be transferred to your selected bank account within 3 days (excluding weekends and holidays). Please note there is a bank withdrawal limit of KES 250,000 per day.</p>
                    <br />
                    <br /> <p>How to make a bank withdraw from your Cashlet account: <a
                        href="https://youtu.be/P9J3WSH5IhM?si=JieCmU115Dk7TNz9"
                        target="_blank"
                        rel="noreferrer"
                        className="faq-green underline font-semibold"
                    >
                        https://youtu.be/P9J3WSH5IhM?si=JieCmU115Dk7TNz9
                    </a></p>
                </div>
        },
        {
            id: 8,
            title: "What is the minimum amount I can withdraw?",
            description:
                <div>
                    <p>
                        The minimum amount you can withdraw will depend on the money market fund that you are using for your saving goals. It is as follows:</p>
                    <div className="border border-gray-600 my-4">
                        <table className="w-full table-auto divide-y divide-gray-600 text-left">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >Name of fund
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >Fund Manager
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 font-bold text-black tracking-wider"
                                    >Minumum amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-600">
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Kasha Money Market Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Orient Asset Managers</td>
                                    <td className="px-2 py-3">KSH 50</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        ICEA Lion Money Market Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">ICEA Lion Asset Management
                                    </td>
                                    <td className="px-2 py-3">KSH 50</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Nabo Money Market Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Nabo Capital</td>
                                    <td className="px-2 py-3">KSH 50</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Nabo Money Market Fund (USD)
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Nabo Capital</td>
                                    <td className="px-2 py-3">USD 50</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Money Market Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Etica Capital</td>
                                    <td className="px-2 py-3">KSH 50</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Money Market Fund (USD)
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Etica Capital</td>
                                    <td className="px-2 py-3">USD 50</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Fixed Income Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Etica Capital</td>
                                    <td className="px-2 py-3">KSH 50</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Shariah Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Etica Capital</td>
                                    <td className="px-2 py-3">KSH 50</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        },
        {
            id: 9,
            title: "What is the maximum amount I can withdraw?",
            description:
                <div>
                    <div className="border border-gray-600 my-4">
                        <table className="w-full table-auto divide-y divide-gray-600 text-left">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >Name of fund
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >Fund Manager
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >Mpesa Maximum Amount
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 font-bold text-black tracking-wider"
                                    >Bank Maximum Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-600">
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Kasha Money Market Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Orient Asset Managers</td>
                                    <td className="px-2 py-3 border-r border-gray-600">KSH 20,000</td>
                                    <td className="px-2 py-3">KSH 250,000</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        ICEA Lion Money Market Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">ICEA Lion Asset Management
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">KSH 20,000</td>
                                    <td className="px-2 py-3">KSH 250,000</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Nabo Money Market Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Nabo Capital</td>
                                    <td className="px-2 py-3 border-r border-gray-600">KSH 20,000</td>
                                    <td className="px-2 py-3">KSH 250,000</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Nabo Money Market Fund (USD)
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Nabo Capital</td>
                                    <td className="px-2 py-3 border-r border-gray-600">USD</td>
                                    <td className="px-2 py-3">USD</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Money Market Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Etica Capital</td>
                                    <td className="px-2 py-3 border-r border-gray-600">KSH 20,000</td>
                                    <td className="px-2 py-3">KSH 250,000</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Money Market Fund (USD)
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Etica Capital</td>
                                    <td className="px-2 py-3 border-r border-gray-600">USD</td>
                                    <td className="px-2 py-3">USD</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Fixed Income Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Etica Capital</td>
                                    <td className="px-2 py-3 border-r border-gray-600">KSH 20,000</td>
                                    <td className="px-2 py-3">KSH 250,000</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Shariah Fund
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">Etica Capital</td>
                                    <td className="px-2 py-3 border-r border-gray-600">KSH 20,000</td>
                                    <td className="px-2 py-3">KSH 250,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        },
        {
            id: 10,
            title: "Am I locked in?",
            description:
                <div>
                    <p>
                        All investments in Kenya shillings in the savings and investment funds available have no lock in period. However, the minimum suggested timeframe you should consider is 6 months for your funds to experience optimal return impact. For Investments in USD, there is a 30 days lock-in period.
                    </p>
                </div>
        },
    ],
    6: [
        {
            id: 1,
            title: "What is the minimum deposit that I can start with?",
            description:
                <div>
                    <p>
                        You can start saving with as little as KSH 100.
                    </p>
                </div>
        },
        {
            id: 2,
            title: "Do I earn interest or returns on Cashlet?",
            description:
                <div>
                    <p>
                        Yes!
                    </p>
                    <br />
                    <br />
                    <p>
                        The current interest rate is between 11 to 16% p.a. depending on the savings and investment fund you select.
                    </p>
                </div>
        },
        {
            id: 3,
            title: "How is my interest paid?",
            description:
                <div>
                    <p>
                        Interest is accrued daily on your balance, and reflects on your dashboard at 10am every day. You can withdraw all or part of the interest on the second day of each new month.
                    </p>
                </div>
        },
        {
            id: 4,
            title: "When does my savings begin to earn interest?",
            description:
                <div>
                    <p>
                        Your funds start to earn interest 2 working days after you make a deposit. Your daily returns are credited to your Cashlet account by 10 am each day.
                    </p>
                </div>
        },
        {
            id: 5,
            title: "How is Cashlet able to offer higher interest/returns than traditional banks?",
            description:
                <div>
                    <p>
                        This is simple.
                    </p>
                    <br />
                    <br />
                    <p>
                        Cashlet is able to offer higher interest or returns than traditional banks because it partners with savings and investment funds, which typically offer better yields on savings compared to traditional bank savings accounts because:
                    </p>
                    <div className="ml-4">
                        <ol>
                            <li>
                                1. Cashlet partners with savings and investment funds, which invest in short-term, low-risk securities like treasury bills, government bonds, and high-quality corporate debt. These investment vehicles often provide better returns compared to savings accounts, which banks primarily use for liquidity management
                            </li>
                            <li>
                                2. Savings and investment funds tend to have focused investment strategies targeting short-term, high-quality financial instruments, which generally provide stable returns. This approach is often more effective than the broader investment strategies employed by banks for savings accounts</li>
                            <li>3. Cashlet, through the savings and investment funds, can offer returns that may keep up with or exceed inflation rates, unlike traditional savings accounts that often offer interest rates below inflation, reducing the real value of savings over time</li>
                        </ol>
                    </div>
                </div>
        },
        {
            id: 6,
            title: "Can I monitor my saving goals and interest?",
            description:
                <div>
                    <p>
                        Absolutely! The dashboards on the mobile app was designed with this in mind. You can monitor the progress on your goals and the returns on a daily basis. On the goal dashboard you can see the following:
                    </p>
                    <div className="ml-4">
                        <ul className="list-disc">
                            <li>
                                Total Balance: This is the net balance on your account taking into consideration all deposits, withdrawals, plus accrued interest
                            </li>
                            <li>
                                Available Balance: This is the amount you can currently withdraw from a goal account. This can be different from the Total balance due to recent deposits and interests earned in the month. When you make a deposit, it is immediately added to your total balance but it only gets added to your available balance after 2 working days
                            </li>
                            <li>Gained Interest: This is the total interest amount accruing in your goal account
                            </li>
                            <li>
                                Weekly change: This is the small arrow below the total funds on the home page which shows how your transactions were over the week on the app and resets every Monday morning
                            </li>
                        </ul>
                    </div>
                </div>
        },
    ],
    7: [
        {
            id: 1,
            title: "What type of Savings and investment products are available on the Cashlet App?",
            description:
                <div>
                    <p>
                        We have several Money Market and Fixed Income Funds on the app. Here is a brief description of what each entail:
                    </p>
                    <br />
                    <br />
                    <p>A Money Market Fund (MMF) is a type of Unit Trust in which funds are invested mostly in financial products that are short term (repaid in less than 1 year) and issued by Government and established institutions (called issuers). Because of this, MMFs are considered low risk and you can get your money back quickly (high liquidity).</p>
                    <br />
                    <br /><p>A USD Money Market Fund (MMF) is an investment option that focuses on short-term, high-quality financial instruments in U.S. dollars, such as Treasury bills, commercial papers, and certificates of deposit. Its primary goal is to preserve capital while providing competitive returns, often exceeding local currency deposits. USD MMFs are ideal for investors seeking to hedge against currency fluctuations, maintain liquidity, and earn stable interest. Additionally, these funds are regulated by the Capital Markets Authority (CMA) in Kenya, ensuring security and transparency for investors.</p>
                    <br />
                    <br /><p>A Fixed Income Fund is an investment vehicle that primarily invests in fixed-income securities, such as bonds, treasury bills, and other debt instruments. These funds aim to provide investors with regular income through interest payments while preserving capital. Fixed income funds are typically considered lower risk compared to equity investments, making them suitable for conservative investors seeking stable returns over time.</p>
                    <br />
                    <br /><p>We plan to add additional savings and investment products on the portfolio to increase your investment options on the app.</p>
                </div>
        },
        {
            id: 2,
            title: "What are the specific funds that I can select on Cashlet App?",
            description:
                <div>
                    <p>Currently, there are 8 savings and investment funds that you can select from:</p>
                    <div className="border border-gray-600 my-4">
                        <table className="w-full table-auto divide-y divide-gray-600 text-left">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >S/No.
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >Name of Money Market Fund
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 font-bold text-black tracking-wider"
                                    >Fund Manager
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-600">
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600">1.</td>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Kasha Money Market Fund
                                    </td>
                                    <td className="px-2 py-3">Orient Asset Managers</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600">2.</td>

                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Nabo Money Market Fund
                                    </td>
                                    <td className="px-2 py-3">Nabo Capital</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600">3.</td>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Nabo Money Market Fund (USD)
                                    </td>
                                    <td className="px-2 py-3">Nabo Capital</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600">4.</td>

                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        ICEA Lion Money Market Fund
                                    </td>
                                    <td className="px-2 py-3">ICEA Lion Asset Management
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600">5.</td>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Money Market Fund
                                    </td>
                                    <td className="px-2 py-3">Etica Capital</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600">6.</td>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Fixed Income Fund
                                    </td>
                                    <td className="px-2 py-3">Etica Capital</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600">7.</td>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Money Market Fund (USD)
                                    </td>
                                    <td className="px-2 py-3">Etica Capital</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600">8.</td>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Etica Shariah Fund
                                    </td>
                                    <td className="px-2 py-3">Etica Capital</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>We are in the process of onboarding additional fund managers onto the Cashlet platform to increase your investment options on the app.</p>
                </div>
        },
        {
            id: 3,
            title: "Why should I save and invest in the funds available on the Cashlet App?",
            description:
                <div className="ml-4">
                    <ul className="list-disc">
                        <li>They are managed by professional investment managers who are government-regulated and know the Kenya financial markets so can identify better places to invest your funds.
                        </li>
                        <li>Your funds are invested across several different financial products. This minimizes potential losses since “your eggs are not kept in one basket”. This is called “risk diversification”.
                        </li>
                        <li>Since Unit Trusts pool funds from several investors, they raise large sums and are able to negotiate for higher interest rates that you will not be able to do on your own.</li>
                    </ul>
                </div>
        },
        {
            id: 4,
            title: "How do I earn interest?",
            description:
                <div>
                    <p>
                        You earn interest from savings and investment funds through the income generated by the underlying financial instruments in which the fund invests. For example, these funds typically invest in assets like government bonds, corporate debt, or treasury bills, which pay interest over time. The fund collects this interest and distributes it to your account daily at 10 am. The amount of interest earned depends on the performance of the underlying investments and the specific terms of the fund.
                    </p>
                </div>
        },
        {
            id: 5,
            title: "How safe is investing in the savings and investment funds?",
            description:
                <div>
                    <p>
                        Investing in the savings and investment funds is safe as they are all regulated by the Capital Markets Authority (CMA). These funds typically invest in low-risk, high-quality financial instruments, which helps to preserve capital. However, it's important to note that all investments carry some level of risk, including market fluctuations and credit risk. It's essential to understand the specific fund's investment strategy and performance history before investing.
                    </p>
                </div>
        },
        {
            id: 6,
            title: "Where do savings and investment funds invest my savings?",
            description:
                <div>
                    <p>
                        This will vary depending on the money market fund that you select but the distribution should give you are fair idea of where your money goes to earn you interest.</p>
                    <div className="border border-gray-600 my-4">
                        <table className="w-full table-auto divide-y divide-gray-600 text-left">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >What MMFs Invest Your Money In
                                    </th>
                                    <th
                                        colSpan={2}
                                        scope="col"
                                        className="px-2 py-3 font-bold text-black tracking-wider"
                                    >Description
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-600">
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Fixed Deposits
                                    </td>
                                    <td className="px-2 py-3  border-gray-600" colSpan={2}>These are investment accounts with fixed interest rates offered by banks such as NCBA, Equity, and Co-operative Bank. These deposits provide a steady, predictable return over a fixed period.</td>
                                </tr>
                                <tr><td className="px-2 py-3 border-r border-gray-600 ">
                                    Securities Issued by the Government of Kenya
                                </td>
                                    <td className="px-2 py-3 border-gray-600" colSpan={2}>These include Treasury Bills and Bonds issued by the Government of Kenya. The government uses these financial products to borrow from investors, promising to pay interest over a set period in return. These instruments are considered low-risk.</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        Commercial Papers
                                    </td>
                                    <td className="px-2 py-3  border-gray-600" colSpan={2}>These are short-term debt instruments issued by large, reputable companies in Kenya to raise quick funds from investors. In return, these corporations pay interest to the investors. Commercial papers are typically higher risk compared to government securities but offer higher returns.
                                    </td>
                                </tr>
                                <tr><td className="px-2 py-3 border-r border-gray-600 ">
                                    Cash and Demand Deposits
                                </td>
                                    <td className="px-2 py-3 border-gray-600" colSpan={2}>These are funds held in normal current and savings accounts at banks such as NCBA, Equity, and Co-operative Bank. While these deposits provide liquidity for fund managers, they generate minimal or no interest.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        }
    ],
    8: [
        {
            id: 1,
            title: "Does Cashlet have a feature to help with locking savings?",
            description:
                <div>
                    <p>
                        Yes, we do! On Cashlet you can choose to lock away your savings for specified periods of time of your liking to help you with financial discipline while you save towards certain goals you want to achieve. Simply select a goal account you want to lock your funds and click on the Lock goal feature. <a
                            href="https://youtu.be/DAlPs2AXyUQ?si=IzQD2ttmd5Gl_wbJ"
                            target="_blank"
                            rel="noreferrer"
                            className="faq-green underline font-semibold"
                        >
                            https://youtu.be/DAlPs2AXyUQ?si=IzQD2ttmd5Gl_wbJ
                        </a>
                    </p>
                </div>
        },
        {
            id: 2,
            title: "How do I unlock my goal account?",
            description:
                <div>
                    <p>
                        When you lock a goal, you authorize that your selected goal cannot be unlocked until the maturity date you set. This means that the locked goal account can only get unlocked after the maturity date you had set. This is to promote financial discipline to those who like to pinch into their savings.
                    </p>
                </div>
        },
        {
            id: 3,
            title: "Can I withdraw from a locked goal account?",
            description:
                <div>
                    <p>
                        No, this is not possible as one can only withdraw from the account when it unlocks after the maturity date of the locked goal account.
                    </p>
                </div>
        },
    ],
    9: [
        {
            id: 1,
            title: "Will Cashlet charge me for anything?",
            description:
                <div>
                    <p>
                        Cashlet is entirely free of charge for any balances under KES 20,000. For balances over KES 20,000, an amount of KES 40 is deducted from your balance monthly.
                    </p>
                    <br />
                    <br />
                    <p>Bank withdrawals are free. </p>
                    <br />
                    <br />
                    <p>Normal M-Pesa fees apply for deposits and M-Pesa withdrawals have transaction charges available on the app such as:</p>
                    <div className="border border-gray-600 my-4">
                        <table className="w-full table-auto divide-y divide-gray-600 text-left">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-gray-600 font-bold text-black tracking-wider"
                                    >
                                    </th>
                                    <th
                                        scope="col"
                                        colSpan={2}
                                        className="px-2 py-3 font-bold text-black tracking-wider text-center"
                                    >
                                        Transaction Fees (KES)
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-t border-gray-600 font-bold text-black tracking-wider"
                                    >
                                        Withdrawal Amount (KES)
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-2 py-3 border-r border-t border-gray-600 font-bold text-black tracking-wider"
                                    >
                                        ICEA Lion
                                    </th>
                                    <th
                                        scope="col"
                                        colSpan={2}
                                        className="px-2 py-3 font-bold border-t text-black border-gray-600 tracking-wider"
                                    >
                                        Others
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-600">
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        0-100
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">0</td>
                                    <td className="px-2 py-3">4</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        101-200
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">5</td>
                                    <td className="px-2 py-3">8</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        201-500
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">5</td>
                                    <td className="px-2 py-3">10</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        501-5,000
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">11</td>
                                    <td className="px-2 py-3">20</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        5,001-20,000
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">11</td>
                                    <td className="px-2 py-3">25</td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-3 border-r border-gray-600 ">
                                        20,001-150,000
                                    </td>
                                    <td className="px-2 py-3 border-r border-gray-600">13</td>
                                    <td className="px-2 py-3">30</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
        },
    ],
    10: [
        {
            id: 1,
            title: "Can I change my phone number on the app?",
            description:
                <div>
                    <p>
                        For security reasons, you cannot change the number associated with your Cashlet account to a new number. If you need to use a new phone number, you will have to delete your current account and create a new one with the new number.</p>
                </div>
        },
        {
            id: 2,
            title: "What happens to my Cashlet account if Lose my phone and/ Simcard?",
            description:
                <div>
                    <p>
                        You can always download the Cashlet App on another device as long as you use the same log in details attached to your available account ie; your passcode and phone number. If you lose a sim card, you have to replace and use the same phone number you used to sign up to the app to access your account.</p>
                </div>
        },
        {
            id: 3,
            title: "Can I add a Next of Kin to my account?",
            description:
                <div>
                    <p>
                        Yes! You can add up to 3 Next of Kins to your Cashlet account. To do so, simply log in, navigate to the "More" section, select "My Profile," and then click on "Next of Kin" to input their details. This ensures your loved ones are securely linked to your account in case of any unforeseen events.</p>
                    <br />
                    <br /><p>
                        Here is a video from our Youtube page to guide you on how to go about this: <a
                            href="https://youtu.be/Qkg0B3z_yLA?si=4c78EgOpdRqMXxEL"
                            target="_blank"
                            rel="noreferrer"
                            className="faq-green underline font-semibold"
                        >
                            https://youtu.be/Qkg0B3z_yLA?si=4c78EgOpdRqMXxEL
                        </a>
                    </p>
                </div>
        },
        {
            id: 4,
            title: "How do I delete my Cashlet Account?",
            description:
                <div>
                    <p>
                        To delete your Cashlet account, log in to the app, click on the "More" function, then select "My Profile." Scroll down to "Account Management" and click on "Close Account." Please note that you can only close your account if your account balance is zero. Be sure to withdraw any remaining funds before proceeding.</p>
                    <br />
                    <br />
                    <p>
                        How to delete your Cashlet account: <a
                            href="https://youtu.be/Mbnxp_nnLVk?si=NRl_R8VFJ6DvkjMO"
                            target="_blank"
                            rel="noreferrer"
                            className="faq-green underline font-semibold"
                        >
                            https://youtu.be/Mbnxp_nnLVk?si=NRl_R8VFJ6DvkjMO
                        </a>
                    </p>
                </div>
        },
    ],
}
export default questions