import React, { useState, useEffect } from 'react'
import "./potentialearnings.css";
import { useSelector } from 'react-redux';

function PotentialEarnings() {
    const calculatorRates = useSelector(state => state.combined.calculatorRates || {
        daily: 0, weekly: 0, monthly: 0, yearly: 0
    });
    const [activeBtn, setActiveBtn] = useState('yearly');
    const [value, setValue] = useState('');
    const [data, setData] = useState({ daily: 0, weekly: 0, monthly: 0, yearly: 0 });
    const handleChange = (inputValue) => {
        let cleanValue = inputValue.replace(/,/g, '');
        let formattedValue = '';
        // Allow only digits and one decimal point
        if (/^\d*\.?\d*$/.test(cleanValue)) {
            // Split into integer and decimal parts
            const parts = cleanValue.split('.');
            let integerPart = parts[0];
            const decimalPart = parts[1] ? '.' + parts[1] : '';

            // Format the integer part with commas
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Combine formatted integer and decimal parts
            formattedValue = integerPart + decimalPart;
        }
        setValue(formattedValue);
        getInterests(inputValue);
    };
    const getInterests = async (num) => {
        const deposit = num.replace(/,/g, '').split('').join('');
        try {
            const resp = await fetch(
                `${process.env.REACT_APP_URL}/api/public/investment-growth/calculator`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        'Accept': "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ deposit: deposit }),
                }
            );

            if (!resp.ok) {
                throw new Error(`HTTP status: ${resp.status}`);
            }
            const data = await resp.json();
            if (data) {
                setData({ daily: Object.values(data.DailyEarning)[0], weekly: Object.values(data.WeeklyEarning)[0], monthly: Object.values(data.MonthlyEarning)[0], yearly: Object.values(data.YearlyEarning)[0] });
            }
        } catch (error) {
        }
    };
    function formatNumber(num) {
        if (isNaN(num)) return '';
        return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };
    useEffect(() => {
        getInterests('100');
    }, []);
    return (
        <div className="overflow-hidden lg:container mx-auto text-center px-8 py-10 md:py-20 lg:py-40 flex items-start gap-4 flex-col md:flex-row">
            <div className='text-center lg:text-left sm:w-full md:w-1/2'>
                <h1 className="text-3xl lg:text-5xl font-medium slide-in-bottom-h1" style={{
                    color: '#343433'
                }}>
                    Calculate Your Potential Earnings
                </h1>
                <div className='text-base lg:text-lg cust-grey-1 mt-4 lg:mt-8'>This example is for illustration only. The interest rate is based on the average of past performance of the money market funds on the Cashlet platform. Past performance does not guarantee or indicate future results. Interest rates vary on a daily basis depending on market conditions</div>
            </div>
            <div className='w-full md:w-1/2 flex flex-col items-center justify-center px-2 mx-auto'>
                <div className='input-wrapper px-2 w-full pb-10 hidden lg:flex'>
                    <div className='cust-grey-1 font-medium text-base'>
                        If you deposit
                    </div>
                    <input
                        id="starting_amount"
                        autoComplete='off'
                        value={value}
                        onChange={(e) => {
                            const val = e.target.value;
                            handleChange(val);
                        }}
                        type="text"
                        style={{
                            fontSize: '36px',
                            lineheight: '44px',
                        }}
                        className="font-medium text-center placeholder-gray-400 mt-1 px-3 block w-full bg-transparent outline-none"
                        placeholder="Ksh 100"
                    />
                </div>
                <div className='input-wrapper-sm px-2 py-3 w-full lg:hidden'>
                    <div className='cust-grey-1 font-medium text-base'>
                        If you deposit
                    </div>
                    <input
                        id="starting_amount"
                        autoComplete='off'
                        value={value}
                        onChange={(e) => {
                            const val = e.target.value;
                            handleChange(val);
                        }}
                        type="text"
                        style={{
                            fontSize: '36px',
                            lineheight: '44px',
                        }}
                        className="font-medium text-center placeholder-gray-400 mt-1 px-3 block w-full bg-transparent outline-none"
                        placeholder="Ksh 100"
                    />
                </div>
                <div className='font-medium text-base text-center my-2'>You could earn a <span className='font-bold'>{activeBtn}</span> compounded interest of:</div>
                <div className='answer-wrapper w-full hidden lg:flex'>
                    <div className='text-4xl font-bold w-full overflow-x-auto overflow-y-hidden'>+Ksh&nbsp;{formatNumber(data?.[activeBtn])}</div>
                </div>
                <div className='answer-wrapper-sm w-full lg:hidden p-3'>
                    <div className='text-4xl font-bold w-full overflow-x-auto overflow-y-hidden'>+Ksh&nbsp;{formatNumber(data?.[activeBtn])}</div>
                </div>
                <div className="flex justify-between my-4 gap-2 lg:gap-4 px-1 lg:px-2 text-sm">
                    {['daily', 'weekly', 'monthly', 'yearly']?.map((el, i) => (
                        <div
                            key={i}
                            className={
                                'btn capitalize flex flex-col ' + (el === activeBtn ? 'btn-active' : 'btn-inactive')
                            }
                            onClick={() => setActiveBtn(el)}
                        >
                            <div>
                                {el}
                            </div>
                            <div>
                                {Math.round(calculatorRates[el] * 100) / 100}&nbsp;%
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PotentialEarnings