import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './assets/css/tailwind.css';
import reportWebVitals from './reportWebVitals';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import appStore from './_store/store';

if (process.env.NODE_ENV === 'development') {
  console.log = () => { };
  console.error = () => { };
  console.warn = () => { };
  console.info = () => { };
  console.debug = () => { };
}

// Create redux store with history
const initialState = {};
const { store, persistor } = appStore(initialState);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// Comment out or remove the reportWebVitals call
// reportWebVitals();
