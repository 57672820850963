import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import googlePlay from "../../assets/img/googlePlay.svg";
import bg9 from "../../assets/img/bg/bg-10.svg";
import Frame1644 from "../../assets/img/Frame1644.png";
// import StoresIcon from '../../../assets/img/combo.png'
import StoresIcon from "../../assets/img/combo.png";
import AppStoreIcon from "../../assets/img/apple.png";
import ReactModal from "react-modal";
import CloseButton from "../../assets/img/closeButton.png";

function BlogBanner() {
  const [WaitListModal, setWaitListModal] = React.useState(false);
  const [SubscriberEmail, setSubscriberEmail] = React.useState("");
  const [SubscriberMobile, setSubscriberMobile] = React.useState("");

  function EmailHandler(e) {
    setSubscriberEmail(e.target.value);
  }

  function PhoneNo_Handler(e) {
    setSubscriberMobile(e.target.value);
  }

  return (
    <div className="relative bg-white">
      <ReactModal
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        preventScroll={true}
        isOpen={WaitListModal}
        ariaHideApp={false}
        className=" mt-52 rounded p-6 shadow-lg bg-white flex w-10/12 lg:w-1/3 mx-auto flex-col"
        style={{
          overlay: {
            backgroundColor: "#00000070",
          },
        }}
      >
        <div className=" w-full">
          <img
            className="relative right-5 h-10 w-10 hover:bg-green-400 rounded-lg "
            onClick={() => {
              setWaitListModal(!WaitListModal);
            }}
            src={CloseButton}
            alt="Play Video"
          />
          <div className="text-2xl tracking-tight font-semibold  text-gray-900 text-center mb-3 mt-1">
            Cashlet Waitlist
          </div>

          <div className="text-center">
            We're bringing Cashlet to Android and iOS soon. Add your details
            below to get notified when it's available for download
          </div>
        </div>

        <div>
          <form className="flex flex-col flex-shrink-0 pt-2 space-y-4">
            <div className="grid grid-cols-6 gap-2">
              <div className="col-span-6 mb-3">
                <label
                  htmlFor="starting_amount"
                  className="block text-sm md:text-md font-medium text-primary-dark"
                >
                  Email
                </label>
                <input
                  id="starting_amount"
                  onChange={(e) => {
                    EmailHandler(e);
                  }}
                  type="text"
                  className="placeholder-gray-400 placeholder-opacity-75 mt-1   p-3  block w-full shadow border border-gray-100 rounded"
                  placeholder="name@mail.com"
                />
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="contribution"
                  className="block text-sm md:text-md font-medium text-primary-dark"
                >
                  Mobile number
                </label>
                <input
                  id="contribution"
                  onChange={(e) => {
                    PhoneNo_Handler(e);
                  }}
                  type="text"
                  placeholder="07xxxxxxxx"
                  className="placeholder-gray-400 placeholder-opacity-75 mt-1 p-3  block w-full shadow border border-gray-100 rounded"
                />
              </div>
            </div>
          </form>

          <div className="py-3">
            <button
              // onClick={() => { MailChimpSubmitHandler() }}
              className="w-full bg-primary-green text-white p-2 rounded-full font-semibold text-lg capitalize mt-3"
            >
              Join Waitlist
            </button>
          </div>
        </div>
      </ReactModal>

      <div
        className="py-6 bg-no-repeat bg-center bg-cover sm:bg-contain"
        style={{ backgroundImage: `url(${bg9})` }}
      >
        <div className="relative sm:container  md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl px-4  mx-auto">
          <div className=" flex items-start justify-between flex-col sm:flex-row">
            <div className="space-y-4 xl:space-y-1 pt-2">
              <h1 className="sm:text-4xl text-2xl xl:text-5xl text-primary-green-dark font-bold">
                Start using Cashlet app{" "}
              </h1>
              <h3 className="sm:text-2xl text-lg text-black font-bold w-9/12 sm:w-full">
                The modern way to save and grow your money{" "}
              </h3>
              <div>
                {/* <a href="https://play.google.com/store/apps/details?id=com.cashlet" className="">
                  <img
                    src={googlePlay}
                    className=" h-10 md:h-12 lg:h-14 rounded-md shadow border border-black bounce-top-icons"
                    alt="i"
                  />
                </a>{' '} */}

                {/* 1_0223 */}
                <div className="flex flex-col md:flex-row gap-3">
                  <div className="flex flex-row pb-0 sm:pb-16 cursor-pointer ">
                    <div
                      onClick={() => {
                        window.location.href =
                          "https://www.clkmg.com/Cashletapp/pswebsite";
                      }}
                      className=" flex flex-row rounded  w-48   shadow-lg overflow-hidden border-gray-400 border text-black font-bold bg-white"
                    >
                      <img
                        className="h-12 w-17  justify-center pl-1"
                        src={StoresIcon}
                      />

                      <div className="flex-column self-center pr-1 ">
                        <div className=" align-text-bottom my-auto font-medium tracking-tight text-xs">
                          Get it on
                        </div>
                        <div className=" align-text-bottom my-auto tracking-base">
                          Google Play
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row pb-5 sm:pb-16 cursor-pointer ">
                    <div
                      onClick={() => {
                        window.open(
                          "https://apps.apple.com/us/app/cashlet-savings-investments/id6463010865",
                          "_blank"
                        );
                      }}
                      className=" flex flex-row rounded  w-48 py-1 md:py-0 shadow-lg overflow-hidden border-gray-400 border text-black font-bold bg-white"
                    >
                      <img
                        className="h-7 w-7  justify-center mt-2 mr-3 ml-4"
                        src={AppStoreIcon}
                      />

                      <div className="flex-column self-center pr-1 ">
                        <div className=" align-text-bottom my-auto font-medium tracking-tight text-xs">
                          Get it on
                        </div>
                        <div className=" align-text-bottom my-auto tracking-base">
                          App Store
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex relative sm:mr-auto sm:ml-20">
              <div className="flex items-start">
                <div className="text-gray-800 font-site leading-relaxed hidden sm:inline-flex items-center mr-4 py-2 whitespace-no-wrap space-x-1 md:space-x-6">
                  <img className="h-8 w-auto sm:h-14" src={logo} alt="Logo" />
                  <span className="font-site text-primary-dark text-xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
                    Cashlet
                  </span>
                </div>{" "}
              </div>{" "}
              <div className="absolute sm:-right-36 -right-80 sm:-top-12 -mt-36 sm:-mt-0 sm:w-48">
                <img
                  className="w-36 h-40 sm:w-full sm:h-full "
                  src={Frame1644}
                  alt="Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogBanner;
