import React from 'react'
import "./openaccount.css";
import Plus from "../../assets/img/plus.png";
import SocialMediaIcons from '../../components/SocialMediaIcons';
import Check from '../../assets/img/check2.png';

function OpenAccount() {
    const items = [{ title: 'Download Cashlet', description: 'Go to the Google Play Store or the Apple App Store and download the Cashlet app' },
    { title: 'Sign Up', description: 'Enter the personal details that you want to use to set up your account' },
    { title: 'Activate your account', description: 'Activate your account using your national ID or other identity document. You will now get access to you our wide range of savings and investment funds' },

    ]
    return (
        <div className='bg-black relative'>
            <img
                src={Plus}
                alt="plus"
                className="bg-img hidden md:block"
            />
            <div className="overflow-hidden lg:container mx-auto text-center px-4 md:px-8 py-4 lg:py-36 md:py-36 grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-0">
                <div className='text-left h-full'>
                    <div className='lg:w-3/4'>
                        <div className='text-base cust-grey-3 mb-5 mt-3 lg:mt-0'>What are you waiting for?</div>
                        <h1 className="sect-title text-6xl font-medium mb-8 hidden lg:block" style={{
                            lineHeight: '80px'
                        }}>
                            Opening a Cashlet account just takes 2 minutes. <span className='sect-sub'>No fuss</span>
                        </h1>
                        <h1 className="sect-title text-4xl font-medium mb-8 lg:hidden" style={{
                            lineHeight: '44px'
                        }}>
                            Opening a Cashlet account just takes 2 minutes. <span className='sect-sub'>No fuss</span>
                        </h1>
                        <div className='flex justify-start'>
                            <SocialMediaIcons borderWhite={true} />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center px-2 h-full'>
                    <div className='lg:w-3/5 h-full flex justify-between flex-col'>
                        {items?.map((el, i) => (
                            <div key={i} className='flex items-start justify-start gap-6 mb-10'>
                                <img
                                    src={Check}
                                    alt="check"
                                    className='w-8 md:w-12'
                                />
                                <div className='flex flex-col gap-4 justify-start items-start text-left'>
                                    <div className='text-white text-xl md:text-xl font-medium'>{el.title}</div>
                                    <div className='cust-grey-3 text-base m-0'>{el.description}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpenAccount