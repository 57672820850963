import React, { useState } from "react";
import "./moneymarketfunds.css";
import Slider from 'react-slick';
import CheckInfo from '../../assets/img/check.png';

function MoneyMarketFunds(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="custom-arrow prev-arrow" onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="55" height="56" viewBox="0 0 55 56" fill="none">
                    <path d="M27.5 51.2266C32.0891 51.2266 36.5752 49.8657 40.391 47.3161C44.2067 44.7665 47.1807 41.1427 48.9369 36.9029C50.6931 32.6631 51.1526 27.9977 50.2573 23.4967C49.362 18.9958 47.1521 14.8614 43.9071 11.6163C40.6621 8.37133 36.5277 6.16145 32.0267 5.26616C27.5257 4.37086 22.8604 4.83036 18.6205 6.58654C14.3807 8.34274 10.7569 11.3167 8.20729 15.1325C5.6577 18.9482 4.29686 23.4343 4.29686 28.0234C4.30369 34.1752 6.75049 40.073 11.1004 44.423C15.4504 48.7729 21.3482 51.2197 27.5 51.2266ZM27.5 9.97656C31.0693 9.97656 34.5585 11.035 37.5263 13.018C40.4941 15.001 42.8072 17.8196 44.1731 21.1172C45.5391 24.4148 45.8964 28.0435 45.2001 31.5442C44.5038 35.045 42.785 38.2606 40.2611 40.7845C37.7372 43.3084 34.5215 45.0272 31.0208 45.7235C27.52 46.4199 23.8914 46.0625 20.5938 44.6966C17.2961 43.3307 14.4776 41.0175 12.4946 38.0497C10.5115 35.082 9.45311 31.5928 9.45311 28.0234C9.45823 23.2387 11.3612 18.6514 14.7446 15.268C18.1279 11.8847 22.7152 9.98168 27.5 9.97656ZM20.5197 29.8475C20.2794 29.6079 20.0887 29.3233 19.9585 29.01C19.8284 28.6966 19.7614 28.3606 19.7614 28.0213C19.7614 27.682 19.8284 27.346 19.9585 27.0326C20.0887 26.7192 20.2794 26.4346 20.5197 26.1951L29.1135 17.6014C29.5978 17.117 30.2547 16.8449 30.9396 16.8449C31.6246 16.8449 32.2815 17.117 32.7658 17.6014C33.2501 18.0857 33.5222 18.7426 33.5222 19.4275C33.5222 20.1125 33.2501 20.7694 32.7658 21.2537L25.9961 28.0234L32.7615 34.7932C33.2458 35.2775 33.5179 35.9344 33.5179 36.6193C33.5179 37.3043 33.2458 37.9612 32.7615 38.4455C32.2772 38.9298 31.6203 39.2019 30.9353 39.2019C30.2504 39.2019 29.5935 38.9298 29.1092 38.4455L20.5197 29.8475Z" fill="#FCFCFD" />
                </svg>
            </div>
        );
    };

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="custom-arrow next-arrow" onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="55" height="56" viewBox="0 0 55 56" fill="none">
                    <path d="M27.5 4.82031C22.9109 4.82031 18.4248 6.18115 14.609 8.73074C10.7933 11.2803 7.81931 14.9042 6.06312 19.144C4.30693 23.3838 3.84743 28.0492 4.74273 32.5501C5.63802 37.0511 7.84791 41.1855 11.0929 44.4305C14.3379 47.6755 18.4723 49.8854 22.9733 50.7807C27.4743 51.676 32.1396 51.2165 36.3795 49.4603C40.6193 47.7041 44.2431 44.7301 46.7927 40.9144C49.3423 37.0987 50.7031 32.6126 50.7031 28.0234C50.6963 21.8717 48.2495 15.9738 43.8996 11.6239C39.5496 7.27394 33.6518 4.82714 27.5 4.82031ZM27.5 46.0703C23.9307 46.0703 20.4415 45.0119 17.4737 43.0289C14.5059 41.0458 12.1928 38.2273 10.8269 34.9297C9.46095 31.632 9.10356 28.0034 9.7999 24.5027C10.4962 21.0019 12.215 17.7863 14.7389 15.2624C17.2628 12.7385 20.4785 11.0197 23.9792 10.3233C27.48 9.62699 31.1086 9.98437 34.4062 11.3503C37.7039 12.7162 40.5224 15.0293 42.5054 17.9971C44.4885 20.9649 45.5469 24.4541 45.5469 28.0234C45.5418 32.8082 43.6388 37.3955 40.2554 40.7789C36.8721 44.1622 32.2848 46.0652 27.5 46.0703ZM34.4803 26.1994C34.7206 26.4389 34.9113 26.7235 35.0415 27.0369C35.1716 27.3503 35.2386 27.6863 35.2386 28.0256C35.2386 28.3649 35.1716 28.7009 35.0415 29.0143C34.9113 29.3276 34.7206 29.6122 34.4803 29.8518L25.8865 38.4455C25.4022 38.9298 24.7453 39.2019 24.0604 39.2019C23.3754 39.2019 22.7185 38.9298 22.2342 38.4455C21.7499 37.9612 21.4778 37.3043 21.4778 36.6193C21.4778 35.9344 21.7499 35.2775 22.2342 34.7932L29.0039 28.0234L22.2385 21.2537C21.7542 20.7694 21.4821 20.1125 21.4821 19.4275C21.4821 18.7426 21.7542 18.0857 22.2385 17.6014C22.7228 17.117 23.3797 16.8449 24.0647 16.8449C24.7496 16.8449 25.4065 17.117 25.8908 17.6014L34.4803 26.1994Z" fill="#FCFCFD" />
                </svg>
            </div>
        );
    };
    const PrevArrowSm = (props) => {
        const { onClick } = props;
        return (
            <div className="custom-arrow-sm prev-arrow-sm" onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="43" viewBox="0 0 42 43" fill="none">
                    <path d="M21 4.21875C24.5044 4.21875 27.9302 5.25794 30.844 7.2049C33.7578 9.15186 36.0289 11.9191 37.37 15.1568C38.7111 18.3945 39.062 21.9572 38.3783 25.3943C37.6946 28.8314 36.0071 31.9885 33.529 34.4665C31.051 36.9446 27.8939 38.6321 24.4567 39.3158C21.0196 39.9995 17.457 39.6486 14.2193 38.3075C10.9816 36.9664 8.21435 34.6953 6.26739 31.7815C4.32043 28.8677 3.28124 25.4419 3.28124 21.9375C3.28645 17.2398 5.15492 12.736 8.4767 9.41421C11.7985 6.09243 16.3023 4.22396 21 4.21875ZM21 35.7188C23.7257 35.7188 26.3901 34.9105 28.6564 33.3962C30.9228 31.8819 32.6891 29.7295 33.7322 27.2114C34.7753 24.6932 35.0482 21.9222 34.5164 19.2489C33.9847 16.5756 32.6722 14.12 30.7448 12.1927C28.8175 10.2653 26.3619 8.95281 23.6886 8.42105C21.0153 7.8893 18.2443 8.16221 15.7261 9.20529C13.2079 10.2484 11.0556 12.0147 9.5413 14.281C8.027 16.5474 7.21874 19.2118 7.21874 21.9375C7.22265 25.5913 8.67586 29.0944 11.2595 31.678C13.8431 34.2616 17.3462 35.7148 21 35.7188ZM15.6696 20.5446C15.4861 20.7275 15.3404 20.9448 15.2411 21.1842C15.1417 21.4235 15.0905 21.68 15.0905 21.9391C15.0905 22.1983 15.1417 22.4548 15.2411 22.6941C15.3404 22.9334 15.4861 23.1508 15.6696 23.3337L22.2321 29.8962C22.602 30.266 23.1036 30.4738 23.6266 30.4738C24.1497 30.4738 24.6513 30.266 25.0212 29.8962C25.391 29.5263 25.5988 29.0247 25.5988 28.5016C25.5988 27.9786 25.391 27.477 25.0212 27.1071L19.8516 21.9375L25.0179 16.7679C25.3877 16.398 25.5955 15.8964 25.5955 15.3734C25.5955 14.8503 25.3877 14.3487 25.0179 13.9788C24.648 13.609 24.1464 13.4012 23.6234 13.4012C23.1003 13.4012 22.5987 13.609 22.2288 13.9788L15.6696 20.5446Z" fill="#FCFCFD" />
                </svg>
            </div>
        );
    };

    const NextArrowSm = (props) => {
        const { onClick } = props;
        return (
            <div className="custom-arrow-sm next-arrow-sm" onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
                    <path d="M21.1865 39.6562C17.6821 39.6562 14.2564 38.6171 11.3425 36.6701C8.42869 34.7231 6.15763 31.9559 4.81654 28.7182C3.47545 25.4805 3.12456 21.9178 3.80824 18.4807C4.49192 15.0436 6.17947 11.8865 8.65748 9.40845C11.1355 6.93044 14.2927 5.24289 17.7298 4.55921C21.1669 3.87553 24.7295 4.22642 27.9672 5.56751C31.2049 6.9086 33.9722 9.17966 35.9191 12.0935C37.8661 15.0073 38.9053 18.4331 38.9053 21.9375C38.9001 26.6352 37.0316 31.139 33.7098 34.4608C30.388 37.7826 25.8842 39.651 21.1865 39.6562ZM21.1865 8.15625C18.4609 8.15625 15.7964 8.96451 13.5301 10.4788C11.2638 11.9931 9.49739 14.1455 8.45432 16.6636C7.41125 19.1818 7.13833 21.9528 7.67008 24.6261C8.20184 27.2994 9.51437 29.755 11.4417 31.6823C13.3691 33.6097 15.8246 34.9222 18.4979 35.4539C21.1712 35.9857 23.9422 35.7128 26.4604 34.6697C28.9786 33.6266 31.1309 31.8603 32.6452 29.594C34.1595 27.3276 34.9678 24.6632 34.9678 21.9375C34.9639 18.2837 33.5107 14.7806 30.927 12.197C28.3434 9.61336 24.8403 8.16016 21.1865 8.15625ZM26.5169 23.3304C26.7005 23.1475 26.8461 22.9302 26.9455 22.6908C27.0448 22.4515 27.096 22.195 27.096 21.9359C27.096 21.6767 27.0448 21.4202 26.9455 21.1809C26.8461 20.9416 26.7005 20.7242 26.5169 20.5413L19.9544 13.9788C19.5846 13.609 19.0829 13.4012 18.5599 13.4012C18.0368 13.4012 17.5352 13.609 17.1654 13.9788C16.7955 14.3487 16.5877 14.8503 16.5877 15.3734C16.5877 15.8964 16.7955 16.398 17.1654 16.7679L22.335 21.9375L17.1686 27.1071C16.7988 27.477 16.591 27.9786 16.591 28.5016C16.591 29.0247 16.7988 29.5263 17.1686 29.8962C17.5385 30.266 18.0401 30.4738 18.5632 30.4738C19.0862 30.4738 19.5879 30.266 19.9577 29.8962L26.5169 23.3304Z" fill="#FCFCFD" />
                </svg>
            </div>
        );
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setActiveIndex(next),
    };
    const settingsLg = {
        ...settings,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    }
    const settingsSm = {
        ...settings,
        prevArrow: <PrevArrowSm />,
        nextArrow: <NextArrowSm />,
    }
    return (
        <div className="mt-10 md:mt-20 lg:mt-40">
            <div className="bg-wrapper-sm hidden-lg">
                <div className="overflow-hidden mx-auto text-center px-4 py-8 ">
                    <div className="lg:hidden">
                        <div className="text-3xl slide-in-bottom-h1 text-white w-3/4 mx-auto">
                            Explore our money market funds
                        </div>
                        <div className="text-extra-muted text-base mx-auto mt-5 text-white">
                            We offer 4 money market saving funds
                        </div>
                    </div>
                    <div className="mt-9">
                        <div style={{margin: '0px 44px'}}>
                            <Slider {...settingsSm}>
                                {props?.data?.map((item, index) => {
                                    return (
                                        <div className="fund-card m-auto flex flex-col items-center" key={index}>
                                            <div className="flex flex-col text-left p-2 fund-bg min-h-fit px-5 pt-5 pb-10 lg:py-5 lg:px-8">
                                                <p className="fund-title font-semibold text-lg capitalize">{item.name}</p>
                                                <div className="fund-subtitle fund-subtitle-sm font-medium">{item.message}</div>
                                                <div className="line"></div>
                                                {item.list?.map((el, ind) => {
                                                    return <div className="flex list list-sm justify-between mb-4" key={ind}>
                                                        <div className="flex justify-center gap-2">
                                                            <div className="check-sm">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                    <g clipPath="url(#clip0_1_1251)">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.26379 13.01C10.7286 13.01 13.5373 10.2012 13.5373 6.73646C13.5373 3.27166 10.7286 0.462891 7.26379 0.462891C3.799 0.462891 0.990234 3.27166 0.990234 6.73646C0.990234 10.2012 3.799 13.01 7.26379 13.01Z" fill="#20E083" fillOpacity="0.2" />
                                                                        <path d="M9.84777 5.21039L9.84779 5.21041C10.0169 5.37952 10.0169 5.65372 9.84779 5.82283L7.00198 8.66864C6.83287 8.83775 6.55866 8.83775 6.38956 8.66865C6.38955 8.66865 6.38955 8.66864 6.38955 8.66864M9.84777 5.21039L6.48651 8.57168L6.38955 8.66864M9.84777 5.21039C9.67863 5.04131 9.40448 5.04129 9.23536 5.21041L6.69578 7.74994L5.4117 6.46586C5.24259 6.29675 4.96838 6.29675 4.79927 6.46586C4.63017 6.63497 4.63017 6.90918 4.79927 7.07828L6.3856 8.66461M9.84777 5.21039L6.3856 8.66461M6.38955 8.66864L6.3856 8.66461M6.38955 8.66864L6.3856 8.66461" fill="white" stroke="white" strokeWidth="0.274254" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_1_1251">
                                                                            <rect width="13.1642" height="12.7528" fill="white" transform="translate(0.600586 0.326172)" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </div>
                                                            {el.key}
                                                        </div>
                                                        {el.key !== 'Riskometer' && el.value}
                                                        {el.key === 'Riskometer' &&
                                                            <div className="flex justify-center items-center" style={{
                                                                gap: "3.75px"
                                                            }}>
                                                                <div style={{
                                                                    borderRadius: '7.236px',
                                                                    background: `${el.value >= 1 ? '#20E083' : '#DDDFED'}`,
                                                                    width: '7.236px',
                                                                    height: '7.236px'
                                                                }}></div>
                                                                <div style={{
                                                                    borderRadius: '7.236px',
                                                                    background: `${el.value >= 2 ? '#20E083' : '#DDDFED'}`,
                                                                    width: '7.236px',
                                                                    height: '7.236px'
                                                                }}></div><div style={{
                                                                    borderRadius: '7.236px',
                                                                    background: `${el.value >= 3 ? '#20E083' : '#DDDFED'}`,
                                                                    width: '7.236px',
                                                                    height: '7.236px'
                                                                }}></div><div style={{
                                                                    borderRadius: '7.236px',
                                                                    background: `${el.value >= 4 ? '#20E083' : '#DDDFED'}`,
                                                                    width: '7.236px',
                                                                    height: '7.236px'
                                                                }}></div><div style={{
                                                                    borderRadius: '7.236px',
                                                                    background: `${el.value >= 5 ? '#20E083' : '#DDDFED'}`,
                                                                    width: '7.236px',
                                                                    height: '7.236px'
                                                                }}></div>
                                                            </div>
                                                        }
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-wrapper hidden-sm">
                <div className="overflow-hidden lg:container mx-auto text-center py-10 lg:py-16">
                    <div>
                        <div className="text-5xl slide-in-bottom-h1 text-white">
                            Money market funds on our platform
                        </div>
                        <div className="text-extra-muted text-xl w-4/5 mx-auto mt-6 text-white">
                            Choose from 4 funds provided by our esteemed partner fund managers
                        </div>
                    </div>
                    <div className="mt-10">
                        <div style={{ padding: '0px 55px' }}>
                            <Slider {...settingsLg}>
                                {props?.pages?.map((items, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="grid grid-cols-2 gap-8 px-1">
                                                {Object.values(items)[0]?.map((item, i) =>
                                                    <div className="fund-card-lg mx-auto flex flex-col items-center" key={i}>
                                                        <div className="flex flex-col w-full text-left p-2 fund-bg min-h-fit px-5 pt-5 pb-10 lg:py-5 lg:px-8">
                                                            <p className="fund-title font-semibold text-xl capitalize">{item.name}</p>
                                                            <div className="fund-subtitle text-xs font-medium">{item.message}</div>
                                                            <div className="line"></div>
                                                            {item.list?.map((el, i) => {
                                                                return <div className="flex list gap-4 mb-4 text-base items-center justify-between" key={i}>
                                                                    <div className="flex flex-nowrap items-center gap-2">
                                                                        <img
                                                                            src={CheckInfo}
                                                                            alt="rating"
                                                                        />
                                                                        {el.key}
                                                                    </div>
                                                                    <div className="flex flex-nowrap items-center gap-2">
                                                                        {el.key !== 'Riskometer' && el.value}
                                                                        {el.key === 'Riskometer' &&
                                                                            <div className="flex justify-center items-center" style={{
                                                                                gap: "3.75px"
                                                                            }}>
                                                                                <div style={{
                                                                                    borderRadius: '7.236px',
                                                                                    background: `${el.value >= 1 ? '#20E083' : '#DDDFED'}`,
                                                                                    width: '7.236px',
                                                                                    height: '7.236px'
                                                                                }}></div>
                                                                                <div style={{
                                                                                    borderRadius: '7.236px',
                                                                                    background: `${el.value >= 2 ? '#20E083' : '#DDDFED'}`,
                                                                                    width: '7.236px',
                                                                                    height: '7.236px'
                                                                                }}></div><div style={{
                                                                                    borderRadius: '7.236px',
                                                                                    background: `${el.value >= 3 ? '#20E083' : '#DDDFED'}`,
                                                                                    width: '7.236px',
                                                                                    height: '7.236px'
                                                                                }}></div><div style={{
                                                                                    borderRadius: '7.236px',
                                                                                    background: `${el.value >= 4 ? '#20E083' : '#DDDFED'}`,
                                                                                    width: '7.236px',
                                                                                    height: '7.236px'
                                                                                }}></div><div style={{
                                                                                    borderRadius: '7.236px',
                                                                                    background: `${el.value >= 5 ? '#20E083' : '#DDDFED'}`,
                                                                                    width: '7.236px',
                                                                                    height: '7.236px'
                                                                                }}></div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MoneyMarketFunds;
