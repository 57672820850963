import { createSlice } from '@reduxjs/toolkit';

export function sortFunds(array) {
  const priority = [
    "Etica Money Market Fund",
    "Kasha Money Market Fund",
    "Etica Fixed Income Fund",
    "ICEA LION Money Market fund",
  ];

  return array.sort((a, b) => {
    const indexA = priority.indexOf(a.name);
    const indexB = priority.indexOf(b.name);

    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
}

// Initial state with default values
export const defaultArr = [
  {
    "name": "Etica Money Market Fund",
    "message": "Etica Capital",
    "list": [
      { "key": "Current interest rate (Today)", "value": `${Math.round((15.8459 + Number.EPSILON) * 100) / 100}%` },
      { "key": "Average interest rate (per year)", "value": `${Math.round((15.8695 + Number.EPSILON) * 100) / 100}%` },
      { "key": "Minimum deposit", "value": `KES 100` },
      { "key": "Riskometer", "value": 1 },
    ]
  },
  {
    "name": "Kasha Money Market Fund",
    "message": "Orient Asset Managers",
    "list": [
      { "key": "Current interest rate (Today)", "value": `${Math.round((13.7034 + Number.EPSILON) * 100) / 100}%` },
      { "key": "Average interest rate (per year)", "value": `${Math.round((13.5215 + Number.EPSILON) * 100) / 100}%` },
      { "key": "Minimum deposit", "value": `KES 100` },
      { "key": "Riskometer", "value": 1 },
    ]
  },
  {
    "name": "Etica Fixed Income Fund",
    "message": "Etica Capital",
    "list": [
      { "key": "Current interest rate (Today)", "value": `${Math.round((14.8184 + Number.EPSILON) * 100) / 100}%` },
      { "key": "Average interest rate (per year)", "value": `${Math.round((14.5124 + Number.EPSILON) * 100) / 100}%` },
      { "key": "Minimum deposit", "value": `KES 100` },
      { "key": "Riskometer", "value": 2 },
    ]
  },
  {
    "name": "ICEA LION Money Market fund",
    "message": "ICEA Lion Asset Management",
    "list": [
      { "key": "Current interest rate (Today)", "value": `${Math.round((12.8 + Number.EPSILON) * 100) / 100}%` },
      { "key": "Average interest rate (per year)", "value": `${Math.round((12.02 + Number.EPSILON) * 100) / 100}%` },
      { "key": "Minimum deposit", "value": `KES 100` },
      { "key": "Riskometer", "value": 1 },
    ]
  }
];

const initialState = {
  mmfs: sortFunds(defaultArr),
  ranges: { lowest: 9, highest: 16.02 },
  aboutData: {
    clientCount: 20000,
    fundCount: 8,
    totalAmount: 120000000,
  },
  calculatorRates: {
    daily: '0',
    weekly: '0',
    monthly: '0',
    yearly: '0',
  }
};

const combinedSlice = createSlice({
  name: 'combined',
  initialState,
  reducers: {
    setCombinedData(state, action) {
      state.mmfs = action.payload.mmfs;
      state.ranges = action.payload.ranges;
    },
    setAboutData(state, action) {
      state.aboutData = action.payload.aboutData;
    },
    setCalculatorRates(state, action) {
      state.calculatorRates = action.payload.calculatorRates;
    },
  },
});

export const { setCombinedData, setAboutData, setCalculatorRates} = combinedSlice.actions;
export const combinedReducer = combinedSlice.reducer;

