/*
 * Blog page that is view if the user is authenticated of not.
 * pages include: Landing page, about page, terms & conditions page (etc.)
 * */

import React, { useEffect } from 'react';
import { useLocation, Route, Switch, Redirect } from 'react-router-dom';
import BlogBanner from '../components/Banner/BlogBanner';
import BlogFooter from '../components/Footer/BlogFooter';
import BlogNavbar from '../components/Navbars/BlogNavbar';
import routes from '../routes/routes';

const BlogLayout = (props) => {
  const [bgClass, setBgClass] = React.useState('');
  const mainContent = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add('font-site', 'tracking-normal', 'text-primary-darkest');
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove('leading-normal', 'tracking-normal', 'text-gray-900');
    };
  });

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  function getRoutes(routes) {
    return routes.map((prop, key) => {
      if (prop.layout === '/blog') {
        return <Route exact path={prop.layout + prop.path} component={prop.component} {...props} key={key} />;
      } else {
        return null;
      }
    });
  }
  return (
    <div ref={mainContent}>
      <section className="relative ">
        <BlogNavbar fixed {...props} />
        {/* Page content */}
        <main className="relative py-10 sm:py-20">
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/blog" to="/blog" />
          </Switch>

          <BlogBanner />
          <BlogFooter />
        </main>
      </section>
    </div>
  );
};
export default BlogLayout;
