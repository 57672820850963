import React, { useEffect, useState, useMemo } from "react";
import LandingImg from "../../assets/img/landing.png";
import ShieldEye from "../../assets/img/ShieldEye.png";
import Control from "../../assets/img/Control.png";
import Track from "../../assets/img/Track.svg";
import World from "../../assets/img/World.png";
import QuickAccess from "../../assets/img/QuickAccess.png";
import ReactGA from "react-ga";
import MoneyMarketFunds from "../../components/MoneyMarketFunds";
import PotentialEarnings from "../../components/PotentialEarnings";
import OpenAccount from "../../components/OpenAccount";
import Questions from "../../components/Questions/Questions";
import SocialMediaIcons from "../../components/SocialMediaIcons";
import { useSelector, useDispatch } from 'react-redux';
import { setCombinedData, sortFunds, setCalculatorRates } from "../../_store/_reducers/ranges.reducers";

const HomePage = () => {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const dispatch = useDispatch();
  const { mmfs, ranges } = useSelector(state => state.combined || {});

  // Memoize MMFs to prevent unnecessary re-renders
  const memoizedMMFs = useMemo(() => {
    return mmfs.map(fund => ({
      name: fund.name,
      message: fund.message,
      list: fund.list,
    }));
  }, [mmfs]);

  // Function to find the highest and lowest interest rates
  function findHighestAndLowestRates(arr) {
    const rates = arr
      .map((item) => parseFloat(item.interestRate))
      .filter((rate) => !isNaN(rate)); // Keep only valid numbers

    if (rates.length === 0) {
      return { highest: null, lowest: null }; // Return null if no valid rates
    }

    const highest = Math.max(...rates);
    const lowest = Math.min(...rates);
    return { highest, lowest };
  }

  const fetchInvestmentData = async () => {
    try {
      const serverResponse = await fetch(`${process.env.REACT_APP_URL}/api/public/investment-options?excludeDeleted=true`);
      if (!serverResponse.ok) throw new Error(`HTTP error! status: ${serverResponse.status}`);

      const jsonResponse = await serverResponse.json();
      if (jsonResponse?.content) {
        const arr = jsonResponse.content.map((el) => ({
          name: el.name,
          message: el.companyName,
          list: [
            {
              key: "Current interest rate (Today)",
              value: `${Math.round((el.interestRate + Number.EPSILON) * 100) / 100}%`,
            },
            {
              key: "Average interest rate (per year)",
              value: `${Math.round((el.avgInterestRate + Number.EPSILON) * 100) / 100}%`,
            },
            {
              key: "Minimum deposit",
              value: `${el.minimumDeposit.currency} ${el.minimumDeposit.amount}`,
            },
            { key: "Riskometer", value: el.risk },
          ],
        }));

        const sortedMMFs = sortFunds(arr);
        const rangesData = findHighestAndLowestRates(jsonResponse.content);
        dispatch(setCombinedData({ mmfs: sortedMMFs, ranges: rangesData }));
        setPages([
          { "page 1": [sortedMMFs[0], sortedMMFs[1]] },
          { "page 2": [sortedMMFs[2], sortedMMFs[3]] },
        ]);
      } else {
        console.error("No content found in the API response");
      }
    } catch (error) {
      console.error("Error fetching investment products:", error);
    }
  };

  const fetchCalculatorData = async () => {
    try {
      const serverResponse = await fetch(
        `${process.env.REACT_APP_URL}/api/public/investment-growth/ratesNew`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body:
            JSON.stringify({ deposit: 100, investmentOptionId: '' }),
        }
      );
      if (!serverResponse.ok) {
        throw new Error(`HTTP error! status: ${serverResponse.status}`);
      }
      try {
        const data = await serverResponse.json();
        const val = { daily: Object.values(Object.values(data.DailyEarning)[0])[0], weekly: Object.values(Object.values(data.WeeklyEarning)[0])[0], monthly: Object.values(Object.values(data.MonthlyEarning)[0])[0], yearly: Object.values(Object.values(data.YearlyEarning)[0])[0] };
        dispatch(setCalculatorRates({ calculatorRates: val }))
      } catch (jsonError) {
        return;
      }
    } catch (error) {
    }
  };

  // Fetch investment data from API
  useEffect(() => {
    fetchInvestmentData();
    fetchCalculatorData();
  }, []);

  // Memoize ranges to prevent unnecessary re-renders
  const memoizedRanges = useMemo(() => ({
    lowest: ranges?.lowest || 9,
    highest: ranges?.highest || 16.02
  }), [ranges]);

  const [pages, setPages] = useState([
    { "page 1": [memoizedMMFs[0], memoizedMMFs[1]] },
    { "page 2": [memoizedMMFs[2], memoizedMMFs[3]] }
  ]);

  return (
    <>
      {/* SECTION 1 */}
      <div className="overflow-hidden lg:container lg:pl-16 mx-auto flex flex-wrap flex-col md:flex-row items-start relative lg:pt-16">
        <div className="flex flex-col gap-2 lg:gap-8 sm:w-full lg:w-1/2 justify-center lg:items-start overflow-y-hidden px-8 text-center lg:text-left">
          <div className="hidden lg:flex">
            <svg
              width="40"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8H17C17.2652 8 17.5196 8.10536 17.7071 8.29289C17.8946 8.48043 18 8.73478 18 9V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V9C0 8.73478 0.105357 8.48043 0.292893 8.29289C0.48043 8.10536 0.734784 8 1 8H2V7C2 6.08075 2.18106 5.1705 2.53284 4.32122C2.88463 3.47194 3.40024 2.70026 4.05025 2.05025C4.70026 1.40024 5.47194 0.884626 6.32122 0.532843C7.17049 0.18106 8.08075 0 9 0C9.91925 0 10.8295 0.18106 11.6788 0.532843C12.5281 0.884626 13.2997 1.40024 13.9497 2.05025C14.5998 2.70026 15.1154 3.47194 15.4672 4.32122C15.8189 5.1705 16 6.08075 16 7V8ZM2 10V18H16V10H2ZM8 12H10V16H8V12ZM14 8V7C14 5.67392 13.4732 4.40215 12.5355 3.46447C11.5979 2.52678 10.3261 2 9 2C7.67392 2 6.40215 2.52678 5.46447 3.46447C4.52678 4.40215 4 5.67392 4 7V8H14Z"
                fill="#233143"
              />
            </svg>
            <span className="text-base font-medium">
              Regulated by the Capital Market Authority
            </span>
          </div>
          <h1 className="hidden lg:block landing-text text-7xl slide-in-bottom-h1 text-left lg:w-4/5"
            style={{ lineHeight: '90px' }}>
            Invest and save wisely
          </h1>
          <h1 className="lg:hidden landing-text text-4xl slide-in-bottom-h1 text-center"
            style={{ lineHeight: 'normal' }}>
            Invest and save wisely
          </h1>
          <div className="text-lg text-muted md:text-xl slide-in-bottom-subtitle">
            Save and invest your money with ease starting with as little as Ksh 100. Earn {Math.round(memoizedRanges.lowest)}-{Math.round(memoizedRanges.highest)}% interests. Set and track progress towards your financial goals. Sign up now
          </div>
          <div className="flex lg:hidden justify-center my-5" style={{
            alignItems: 'flex-start',
            gap: '0.5rem'
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <path d="M16.3333 8.33464H17.1667C17.3877 8.33464 17.5996 8.42243 17.7559 8.57871C17.9122 8.73499 18 8.94696 18 9.16797V17.5013C18 17.7223 17.9122 17.9343 17.7559 18.0906C17.5996 18.2468 17.3877 18.3346 17.1667 18.3346H3.83333C3.61232 18.3346 3.40036 18.2468 3.24408 18.0906C3.0878 17.9343 3 17.7223 3 17.5013V9.16797C3 8.94696 3.0878 8.73499 3.24408 8.57871C3.40036 8.42243 3.61232 8.33464 3.83333 8.33464H4.66667V7.5013C4.66667 6.73526 4.81755 5.97672 5.1107 5.26898C5.40386 4.56125 5.83354 3.91819 6.37521 3.37651C6.91689 2.83484 7.55995 2.40516 8.26768 2.112C8.97541 1.81885 9.73396 1.66797 10.5 1.66797C11.266 1.66797 12.0246 1.81885 12.7323 2.112C13.4401 2.40516 14.0831 2.83484 14.6248 3.37651C15.1665 3.91819 15.5961 4.56125 15.8893 5.26898C16.1825 5.97672 16.3333 6.73526 16.3333 7.5013V8.33464ZM4.66667 10.0013V16.668H16.3333V10.0013H4.66667ZM9.66667 11.668H11.3333V15.0013H9.66667V11.668ZM14.6667 8.33464V7.5013C14.6667 6.39623 14.2277 5.33643 13.4463 4.55502C12.6649 3.77362 11.6051 3.33464 10.5 3.33464C9.39493 3.33464 8.33512 3.77362 7.55372 4.55502C6.77232 5.33643 6.33333 6.39623 6.33333 7.5013V8.33464H14.6667Z" fill="#233143" />
            </svg>
            <span className="text-sm lg:text-base font-medium text-left">
              Regulated by the Capital Market Authority
            </span>
          </div>
          <SocialMediaIcons />
        </div>
        <div
          className="sm:w-full lg:w-1/2 mt-3 lg:mt-0"
        >
          <img
            className="object-contain w-full"
            src={LandingImg}
            alt="landing"
          />
        </div>
      </div>
      {/* SECTION 2*/}
      <div className="overflow-hidden lg:container mx-auto text-center px-8 py-10 md:py-20 lg:py-40">
        <h1 className="hidden lg:block landing-subtitle text-6xl slide-in-bottom-h1 lg:w-2/3 lg:mx-auto"
          style={{ lineHeight: '72px' }}>
          get interest paid daily, monthly and yearly
        </h1>
        <h1 className="lg:hidden landing-subtitle text-3xl slide-in-bottom-h1"
          style={{ lineHeight: 'normal' }}>
          get interest paid daily, monthly and yearly
        </h1>
        <p className="hidden lg:block mt-6 text-muted lg:text-3xl slide-in-bottom-subtitle mb-11 w-2/4 mx-auto"
          style={{ lineHeight: '38px' }}>
          Open an account easily and watch your money grow and multiply daily.
        </p>
        <div className="lg:hidden text-base md:text-xl text-muted mb-4 md:w-2/4 mx-auto mt-6"
          style={{ lineHeight: 'normal', marginTop: '44px !important' }}>
          Open an account easily and watch your money grow and multiply daily.
        </div>
        <div className="hidden lg:flex justify-center items-center lg:items-baseline">
          <h1 className="landing-subtitle text-2xl lg:text-5xl slide-in-bottom-h1">
            up to
          </h1>
          <h1 className="landing-subtitle text-custom-240">
            {Math.round((memoizedRanges.highest + Number.EPSILON) * 100) / 100}%*
          </h1>
        </div>
        <div className="flex lg:hidden flex-col justify-center items-center">
          <h1 className="landing-subtitle text-2xl lg:text-5xl slide-in-bottom-h1">
            up to
          </h1>
          <h1 className="landing-subtitle text-custom-80">
            {Math.round((memoizedRanges.highest + Number.EPSILON) * 100) / 100}%*
          </h1>
        </div>
        <div className="hidden lg:block text-extra-muted text-sm w-10/12 mx-auto">
          The interest rate changes each day, and your returns may vary depending on your money market fund and market conditions. The rate is displayed on a per year basis and it is after applicable withholding taxes have been applied. T&Cs apply.
        </div>
        <div className="lg:hidden text-extra-muted mx-auto" style={{ fontSize: '8px', lineHeight: '14px' }}>
          The interest rate changes each day, and your returns may vary depending on your money market fund and market conditions. The rate is displayed on a per year basis and it is after applicable withholding taxes have been applied. T&Cs apply.
        </div>
      </div>
      {/* SECTION 3 */}
      <div className="overflow-hidden lg:container mx-auto text-center px-8">
        <h1 className="landing-text3 text-2xl lg:text-4xl slide-in-bottom-h1 md:w-4/5 lg:w-2/5 mx-auto mb-8 lg:mb-20">
          Experience saving and investing in a whole new way.
        </h1>
        <div className="card-wrapper lg:w-4/6 mx-auto hidden md:grid md:grid-cols-3">
          <div className="landing-card max-w-none mx-auto flex flex-row items-center p-4 col-span-2">
            <div className="w-2/3 text-left">
              <div className="card-title w-4/6 mx-auto ml-0 text-2xl fw-bold">Enjoy safety and top notch security</div>
              <div className="card-content mt-2"
                style={{
                  fontSize: '12.847px',
                  lineHeight: '22.481px'
                }}>The investment products on Cashlet are managed by fund managers that are authorized and regulated by the Capital Markets Authority in Kenya.</div>
            </div>
            <img
              src={ShieldEye}
              alt="shield eye"
              className="w-48 ml-auto"
              loading="lazy"
            />
          </div>
          <div className="landing-card flex flex-col items-start p-4">
            <div
              style={{ alignSelf: 'center' }}>
              <img
                src={QuickAccess}
                alt="quick access"
                style={{ width: '120px' }}
                loading="lazy"
              />
            </div>
            <div className="flex flex-col text-left">
              <div className="card-content"
                style={{
                  fontSize: '12.345px',
                  lineHeight: '19.27px'
                }}> Quick Access</div>
              <div className="card-title text-2xl sm:fw-semibold fw-bold">Funds available</div>
              <div className="card-title text-2xl sm:fw-semibold fw-bold">within 1 working day</div>
            </div>
          </div>
        </div>
        <div className="card-wrapper-sm md:hidden mx-auto">
          <div className="landing-card sm:max-w-98 lg:max-w-none mx-auto flex flex-col items-center p-2 mb-3">
            <div className="w-1/3">
              <img
                src={ShieldEye}
                alt="shield eye"
                className="h-20 lg:h-36 mx-auto"
                loading="lazy"
              />
            </div>
            <div className="card-title mx-auto text-xl fw-semibold mt-7 w-4/5" style={{ lineHeight: 'normal' }}>Enjoy safety and top notch security</div>
            <p className="card-content text-base">The investment products on Cashlet are managed by fund managers that are authorized and regulated by the Capital Markets Authority in Kenya.</p>
          </div>
          <div className="landing-card sm:max-w-98 lg:max-w-none mx-auto flex flex-col items-center p-2 lg:p-7 mb-3">
            <div>
              <img
                src={QuickAccess}
                alt="quick access"
                className="h-20"
                loading="lazy"
              />
            </div>
            <div className="flex flex-col text-center">
              <div className="card-content text-base mb-0 mt-7">Quick Access</div>
              <p className="card-title mx-auto text-xl fw-semibold my-0 w-3/4">Funds available within 1 working day</p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:w-4/6 mx-auto gap-4 mt-4">
          <div className="landing-card flex flex-col justify-between h-76 lg:h-72 mb-3 lg:mb-0">
            <div>
              <img
                src={World}
                alt="world"
                className="h-full"
                loading="lazy"
              />
            </div>
            <div className="flex flex-col text-center md:text-left p-2 mb-2" style={{ height: '108px' }}>
              <p className="card-title mx-auto md:ml-0 text-xl md:text-sm sm:fw-semibold fw-bold my-0">Accessible to everyone</p>
              <div className="card-content text-base md:text-xs mb-0">Customers can start saving on the Cashlet app with as little as Ksh100.</div>
            </div>
          </div>
          <div className="landing-card flex flex-col justify-between overflow-hidden h-76 lg:h-72 mb-3 lg:mb-0">
            <div className="pt-2 md:pt-4">
              <img
                src={Control}
                alt="Control"
                className="h-full m-auto w-full"
                loading="lazy"
              />
            </div>
            <div className="flex flex-col text-center md:text-left p-2 mb-2 md:pt-4 lg:pt-2" style={{ height: '110px' }}>
              <p className="card-title mx-auto md:ml-0 text-xl md:text-sm sm:fw-semibold fw-bold my-0">Enjoy control and flexibility</p>
              <div className="card-content text-base md:text-xs mb-0">Customers can select from 4+ money market funds and track their savings.</div>
            </div>
          </div>
          <div className="landing-card flex flex-col justify-between h-76 lg:h-72">
            <div className="py-2 md:py-5">
              <img
                src={Track}
                alt="Track"
                className="h-full m-auto"
                loading="lazy"
              />
            </div>
            <div className="flex flex-col text-center md:text-left p-2 mb-2" style={{ height: '108px' }}>
              <p className="card-title mx-auto md:ml-0 text-xl md:text-sm sm:fw-semibold fw-bold my-0">Set and track your goals</p>
              <div className="card-content text-base md:text-xs mb-0">Name the goals you want to achieve, set the amount, track your progress.</div>
            </div>
          </div>
        </div>
      </div>
      {/* SECTION 4 */}
      {/* <div className="overflow-hidden lg:container mx-auto text-center px-8 py-10 md:py-20 lg:py-40">
        <div className="hidden lg:block">
          <h1 className="landing-subtitle text-3xl lg:text-6xl slide-in-bottom-h1">
            our customers <span className="text-custom-green">love</span> us
          </h1>
          <p className="text-extra-muted text-base lg:text-lg w-4/5 mx-auto mt-2">
            Here's what they're saying
          </p>
        </div>
        <div className="lg:hidden">
          <h1 className="landing-subtitle text-3xl lg:text-6xl slide-in-bottom-h1">
            users trust us for their <span className="text-custom-green">saving</span> needs
          </h1>
          <p className="text-extra-muted text-base lg:text-lg mx-auto mt-2">
            Don’t take our word for it. See for yourself.
          </p>
        </div>
        <div className="mt-4 lg:mt-10">
          <Testimonials />
        </div>
      </div> */}
      {/* SECTION 5 */}
      {<MoneyMarketFunds data={memoizedMMFs} pages={pages} />}
      {/* SECTION 6 */}
      <PotentialEarnings />
      {/* SECTION 7 */}
      <OpenAccount />
      {/* SECTION 8 */}
      <Questions />
    </>
  );
};

export default HomePage;
