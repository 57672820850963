import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlogListSection from './BlogListSection';
import SearchForm from '../../../components/Blog/SearchForm/SearchForm';
import BannerCard from '../../../components/Blog/BannerCard/BannerCard';
import SubscribeCard from '../../../components/Blog/SubcribeCard/SubscribeCard';
import ShareLinks from '../../../components/Blog/ShareLinks/ShareLinks';
import { articleActions } from '../../../_store/_actions';
import Moment from 'react-moment';
import { getStrapiMedia } from '../../../utils/media';
import { Link } from 'react-router-dom'
import bg6 from '../../../assets/img/bg/bg-6.svg'
import custom from '../../../assets/img/bg/combo.png'
import ReactGA from 'react-ga'


function VerticalCard({ article }) {
  return (
    <Link to={`/blog/${article.slug}`}>
      <div className="grid grid-cols-6 gap-6">
        <div
          className="  mt-4 bg-contain bg-no-repeat col-span-2 bg-top   w-full h-auto p-4  rounded-lg"
          style={{ backgroundImage: article.image.length > 0 ? `url(${getStrapiMedia(article.image[0].url)})` : null }}

        ></div>
        <div className="col-span-4 space-y-2 xl:space-y-6">
          <div className="space-y-2 xl:space-y-3">
            <h3 className=" text-base xl:text-lg font-bold text-gray-900">{article.title}</h3>
            <p className="text-2sm xl:text-base text-gray-600  sm:mx-auto tracking-tight  lg:mx-0">
              {article.description.substr(0, 70) + '\u2026'}
            </p>
          </div>
          <div className="flex justify-between items-center text-sm text-gray-700">
            <div className=" inline-flex items-center space-x-3">
              <p className="text-gray-900 font-bold capitalize">{article.category.name}</p>
              <p className="text-gray-900 font-bold"> • </p>
              <p>{article.read_duration} min </p>
            </div>
            <div className=" inline-flex items-center">
              <p>
                {' '}
                <Moment format="MMM DD, YYYY">{article.published_at}</Moment>
              </p>
            </div>
          </div>
        </div>{' '}
      </div>
    </Link>
  );
}

{/* <Link to={`/blog/${article.slug}`} className="space-y-4"></Link> */ }

function BlogListPage() {
  const { alert, article } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(articleActions.filterArticles('?status=published'));
    dispatch(articleActions.allArticles('?status=published'));
    dispatch(articleActions.listCategories());

  }, [dispatch]);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  // console.log("eusebio_categories", article);//DEBUG
  // console.log("typeOff",typeof article.articles);//DEBUG

  // if (typeof article.image[0].url != "undefined") {

  const filterByCategory = (id) => {
    dispatch(articleActions.filterArticles(`?category=${id}`));
  }

  const fetchAllArticles = () => {
    dispatch(articleActions.filterArticles('?status=published'));
  }

  const searchArticle = (searchTerm) => {
    dispatch(articleActions.filterArticles(`?[title_contains]=${searchTerm}`));
  }

  return (
    <div className="relative ">
      <div className="py-10 bg-primary-gray-light">
        <div className="sm:container md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl mx-auto px-4 w-full ">
          <div className="sm:grid sm:grid-cols-6 gap-4 ">
            <div className="sm:col-span-6">

              <div className="hidden md:block absolute md:-right-0 lg:right-32 md:top-full lg:top-9">
                <img className="w-32  opacity-40" src={bg6} alt="backgroundimage" />
              </div>

              <div className="hidden md:block absolute md:-right-0 lg:-right-0 md:top-full lg:top-9">
                <img className="w-32  opacity-40" src={custom} alt="backgroundimage" />
              </div>

              <div className="py-2 space-y-3">
                <div className="sm:hidden flex">
                  <SearchForm searchArticle={searchArticle} />
                </div>
                <h3 className="text-2xl tracking-tight font-bold sm:max-w-2xl md:max-w-4xl lg:max-w-4xl text-gray-900  md:text-4xl lg:text-3xl xl:text-5xl">
                  Money Moves
                </h3>
                <p className=" text-xs text-primary-green-dark  sm:text-lg sm:mx-auto  md:text-lg lg:mx-0">
                  A Cashlet App blog
                </p>
                <p className="lg:w-3/5  text-base text-gray-600  sm:text-lg sm:mx-auto  md:text-xl lg:text-lg xl:text-xl lg:mx-0">
                  Personal finance doesn't have to be complicated. Get the latest money advice, helpful tools and
                  tailored insights from the Cashlet team
                </p>
              </div>
            </div>
            <div className="col-span-3">
              <div className="space-y-3 py-12">
                <h6 className=" text-xl text-primary-purple  sm:text-lg sm:mx-auto font-bold tracking-wide  md:text-xl lg:mx-0">
                  Recommended read
                </h6>
                {article?.allArticles?.map(
                  (article) =>
                    article.important && (
                      <Link to={`/blog/${article.slug}`} className="space-y-4">
                        <div className="space-y-8">
                          <div
                            className="bg-cover bg-center h-80 p-4  rounded-lg"
                            // style={{ backgroundImage: `url(${getStrapiMedia(article.image[0].url)})` }}
                            style={{ backgroundImage: article.image.length > 0 ? `url(${getStrapiMedia(article.image[0].url)})` : null }}

                          >
                            <div className="flex justify-start ">
                              <div className="flex  item-start align-top border border-primary-blue rounded py-1 pr-2 bg-white ">
                                <svg
                                  className="h-4 w-6 text-primary-blue fill-current"
                                  viewBox="0 0 12 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M1.33333 3.06941V9.19275C1.33334 9.63172 1.44172 10.0639 1.64884 10.4509C1.85597 10.838 2.15543 11.1679 2.52067 11.4114L6 13.7314L9.47933 11.4114C9.84447 11.168 10.1439 10.8381 10.351 10.4512C10.5581 10.0643 10.6665 9.63227 10.6667 9.19341V3.06941L6 2.03341L1.33333 3.06941ZM0.522 1.88408L6 0.666748L11.478 1.88408C11.626 1.91699 11.7584 1.99939 11.8533 2.11768C11.9482 2.23598 12 2.38309 12 2.53475V9.19275C12 9.85124 11.8373 10.4995 11.5266 11.0801C11.2158 11.6606 10.7666 12.1555 10.2187 12.5207L6 15.3334L1.78133 12.5207C1.23352 12.1556 0.784307 11.6608 0.473565 11.0804C0.162824 10.5 0.000157571 9.85179 0 9.19341V2.53475C2.60252e-05 2.38309 0.0517586 2.23598 0.14666 2.11768C0.241561 1.99939 0.373958 1.91699 0.522 1.88408ZM6 9.00008L4.04067 10.0301L4.41467 7.84875L2.83 6.30341L5.02067 5.98475L6 4.00008L6.98 5.98475L9.17 6.30341L7.58533 7.84875L7.95867 10.0301L6 9.00008Z" />
                                </svg>
                                <span className=" text-xs text-primary-blue uppercase font-semibold">important</span>
                              </div>
                            </div>
                          </div>
                          <div className="space-y-3">
                            <h3 className="w-4/6  text-2xl lg:text-xl xl:text-2xl font-bold text-gray-900">
                              {article.title}
                            </h3>
                            <p className="text-base text-primary-gray-dark   xl:text-lg sm:mx-auto tracking-tight  lg:mx-0">
                              {article.description}
                            </p>
                          </div>
                          <div className="flex justify-between items-center text-gray-700">
                            <div className=" inline-flex items-center">
                              <p>
                                In: <span className="text-gray-900 font-bold capitalize">{article.category.name}</span>
                              </p>
                            </div>
                            <div className=" inline-flex items-center">
                              <p>{article.read_duration} min read</p>
                            </div>
                            <div className=" inline-flex items-center">
                              <p>
                                {' '}
                                <Moment format="MMM DD, YYYY">{article.published_at}</Moment>
                              </p>
                              <svg
                                className="h-5 w-5 text-primary-purple-dark fill-current ml-3"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M10.5449 2.00008H13.2116C13.3884 2.00008 13.558 2.07032 13.683 2.19534C13.808 2.32037 13.8783 2.48994 13.8783 2.66675V13.3334C13.8783 13.5102 13.808 13.6798 13.683 13.8048C13.558 13.9298 13.3884 14.0001 13.2116 14.0001H1.21159C1.03478 14.0001 0.865208 13.9298 0.740184 13.8048C0.61516 13.6798 0.544922 13.5102 0.544922 13.3334V2.66675C0.544922 2.48994 0.61516 2.32037 0.740184 2.19534C0.865208 2.07032 1.03478 2.00008 1.21159 2.00008H3.87826V0.666748H5.21159V2.00008H9.21159V0.666748H10.5449V2.00008ZM9.21159 3.33341H5.21159V4.66675H3.87826V3.33341H1.87826V6.00008H12.5449V3.33341H10.5449V4.66675H9.21159V3.33341ZM12.5449 7.33341H1.87826V12.6667H12.5449V7.33341Z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </Link>

                    )
                )}
              </div>
            </div>
            <div className="col-span-3">
              <div className="sm:bg-white sm:ml-8 py-5 sm:py-10 sm:px-10 rounded-xl">
                <div className="sm:space-y-3 space-y-5">
                  <h6 className=" text-xl text-primary-purple  sm:text-lg sm:mx-auto font-bold tracking-wide  md:text-xl lg:mx-0">
                    Popular post
                  </h6>
                  <div className="space-y-6">
                    {article?.allArticles?.slice(0, 3).map((article, i) => {
                      return <VerticalCard article={article} key={`article__${article.id}`} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-2 bg-white">
        <div className="sm:container md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl mx-auto px-4  ">
          <div className="grid sm:grid-cols-6 sm:gap-8 ">
            <div className="col-span-6">
              <div className="py-10">
                <div className="flex flex-wrap space-x-1 sm:space-x-4 sm:space-y-4 lg:space-y-0">
                  <button
                    className="
                    flex items-center justify-center my-2 xs:my-0 px-3 py-2 text-xs sm:text-sm xl:text-base font-normal text-primary-gray-dark  border-2 border-primary-green-dark  rounded-2xl focus:outline-none focus:bg-primary-green-dark focus:text-white"
                    onClick={() => {
                      fetchAllArticles()
                    }}
                  >
                    All topics
                  </button>

                  {article.Listed_categories && article.Listed_categories.map(
                    (classification) =>
                      <button onClick={() => {filterByCategory(classification.id)}} className=" flex items-center my-2 xs:my-0 justify-center px-3 py-2 text-xs sm:text-sm xl:text-base font-normal text-primary-gray-dark  border-1 border-gray-200  rounded-2xl focus:outline-none focus:bg-primary-green-dark focus:text-white">
                        {classification.name}
                      </button>
                  )}

                  {/* <button className=" flex items-center my-2 xs:my-0 justify-center px-3 py-2 text-xs sm:text-sm xl:text-base font-normal text-primary-gray-dark  border-1 border-gray-200  rounded-2xl focus:outline-none focus:bg-primary-green-dark focus:text-white">
                    Budgeting
                  </button>
                  <button className=" flex items-center my-2 xs:my-0 justify-center px-3 py-2 text-xs sm:text-sm xl:text-base font-normal text-primary-gray-dark  border-1 border-gray-200  rounded-2xl focus:outline-none focus:bg-primary-green-dark focus:text-white">
                    Savings
                  </button>
                  <button className=" flex items-center my-2 xs:my-0 justify-center px-3 py-2 text-xs sm:text-sm xl:text-base font-normal text-primary-gray-dark  border-1 border-gray-200  rounded-2xl focus:outline-none focus:bg-primary-green-dark focus:text-white">
                    Investing
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-span-4">
              {article.articles.length > 0 ? <BlogListSection articles={article.articles} /> : <p>No articles found</p>}
            </div>
            <div className="col-span-2">
              <div className="py-2 space-y-4 sticky inset-0 hidden max-h-full lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-4 lg:my-0">
                {/* search form */}
                <SearchForm searchArticle={searchArticle} />
                {/* card */}
                <BannerCard />
                <SubscribeCard />
                {/* share */}
                <ShareLinks />
              </div>
            </div>
          </div>
          <div className="sm:hidden flex flex-col space-y-5">
            <SubscribeCard />
            <ShareLinks />
          </div>
        </div>
      </div>
    </div>
  )
  // } else {
  //   return (null)
  // }


}

export default BlogListPage;
