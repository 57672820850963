// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import createReducer from './_reducers';

// export default function appStore(initialState = {}) {
//   return configureStore({
//     reducer: createReducer(),
//     middleware: [...getDefaultMiddleware()],
//     preloadedState: initialState,
//     devTools: process.env.NODE_ENV !== 'production',
//   });
// }

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createReducer from './_reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
};

export default function appStore(initialState = {}) {
  const rootReducer = createReducer();

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: [...getDefaultMiddleware()],
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });

  const persistor = persistStore(store);

  return { store, persistor };
}