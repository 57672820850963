import React from "react";
import FAQ from "./FAQ";
import "./questions.css";

export default function Questions() {
  const questionList = [
    {
      id: 1,
      title: "What is Cashlet?",
      description:
        "Cashlet is a savings and investment super-app that provides you with easy and convenient tools to manage, grow, and monitor their money so that they can achieve their financial goals. <br /> <br /> You go through one simple and fully digital process to sign up and gain access to the largest variety of investment savings and investment products in Kenya. <br />  <br />We currently have 8 money market funds for you to choose from on the platform... with more to come. Once onboarded, you are supported by smart tools such as lock goals, payday savings, automatic deposits, and group savings to save, invest, and growth your funds",
    },
    {
      id: 2,
      title: "How Safe Is Investing My Money On Cashlet?",
      description:
        "Cashlet is regulated by the Capital Markets Authority (CMA), ensuring the security of your funds and preventing fraudulent activities. You can look this up or find further information directly on the <span><a href='https://www.cma.or.ke/cma-graduates-sycamore-capital-limited-from-the-regulatory-sandbox/' target='_blank' rel='noreferrer'>CMA's website</a></span>.<br /> <br />We only work with fund managers such as (ICEA Lion, Etica, Nabo Capita, Orient Asset Managers etc) are are also licensed and regulated by the CMA. <br /> <br /> Additionally, Cashlet employs bank-level encryption to safeguard your money, ensuring only you have access to your data, which is never stored or used without your consent"
    },
    {
      id: 3,
      title: "How Do I Benefit From Investing On Cashlet?",
      description:
        "You get to enjoy benefits such as attractive interest rates on your funds (up to 16% p.a), instant access to funds when you want to withdraw, option to lock your funds to stay disciplined, flexibility to select from up to 8+ money market funds, smart money management tools, and ability to monitor growth of your funds in real time.",
    },
    {
      id: 4,
      title: "How Do My Funds Gain Interest?",
      description:
        "When you invest in any of the savings and investment products on our platform it is invested by our partner fund managers in financial assets like treasury bills, bonds, and corporate debt. These investments generate stable returns, which are then passed back to you in the form of daily interest. Interest is credited to you at 10am each day. Fund managers aim to maximize returns while minimizing risk.",
    },
    {
      id: 5,
      title: "What Are The Investment Options You Have On The App?",
      description:
        "We have 8 savings and investment products on our platform with more on the way. These include money market. fixed income, USD and Shariah funds. These products are offered and managed by regulated fund managers in Kenya such as ICEA Lion, Etica, Nabo Capita, and Orient Asset Managers.",
    },
    {
      id: 6,
      title: "Where Is Your Office Located?",
      description:
        "Our Office is located at Laiboni Centre, 4th Floor, Lenana Road, Kilimani, Nairobi. Feel free to pop-in anytime to speak and connect with our team. <br /><br /> Contact Number: 0112029738 <br /> Email: <p><span>team@cashlet.co.ke</span></p>",
    },
    {
      id: 7,
      title: "Can I Make A Withdrawal Anytime?",
      description:
        "You can withdraw all or part of your funds any time you want into your M-pesa or Bank account.<br /> <br />You can withdraw up to KES 10,000 to your M-pesa account per day and receive the funds within 1 working day.<br /> <br />Withdrawal to your bank account can be up to a limit of KES 250,000 per day and funds are credited in 2-3 working days",
    },
  ];

  return (
      <div
        className="overflow-hidden lg:container mx-auto pt-10 md:pt-20 lg:pt-40"
      >
        <div className="faq-wrapper m-auto text-center mb-10 md:mb-24 lg:mb-34 px-4">
          <h1 className="w-2/3 lg:w-full mx-auto faq-title text-2xl md:text-4xl font-black slide-in-bottom-h1">
            Frequently Asked Questions
          </h1>
          <div className="w-4/5 lg:w-full mx-auto text-base faq-help mt-2 lg:mt-0">Get the answers to your questions about Cashlet.</div>
          <div className="mt-10 md:mt-20 w-full lg:w-2/3 m-auto">
            <FAQ>
              {questionList.map((question) => (
                <FAQ.QAItem id={question.id} key={question.id}>
                   <FAQ.Question
                    title={question.title}
                    answerId={question.id}
                  />
                  <FAQ.Answer id={question.id}>
                    {question.description}
                  </FAQ.Answer>
                </FAQ.QAItem>
              ))}
            </FAQ>
          </div>
          <a
            href="/FAQ" className="help-text flex justify-center gap-3 text-center my-4 lg:my-10">
            <span className="border-b-2 border-color">Show more FAQs</span>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                <g clipPath="url(#clip0_1_582)">
                  <path d="M16.672 11.5457L11.308 5.9582L12.722 4.48529L20.5 12.5874L12.722 20.6895L11.308 19.2165L16.672 13.629H4.5L4.5 11.5457H16.672Z" fill="black" />
                </g>
                <defs>
                  <clipPath id="clip0_1_582">
                    <rect width="25" height="24" fill="white" transform="matrix(0 -1 1 0 0.5 25.0249)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </a>
        </div>
      </div>
  );
}

