import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { articleActions } from '../../../_store/_actions';
import BlogItem from '../BlogListPage/BlogItem';
import BlogDescriptionSection from './BlogDescriptionSection';

function BlogDescriptionPage() {
  let { slug } = useParams();
  const { alert, article } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(articleActions.getSingleArticle(slug));
    dispatch(articleActions.filterArticles('?status=published&_limit=3'));
  }, [dispatch, slug]);

  return (
    <div className="py-4 bg-white">
      {Object.keys(article.singleArticle).length > 0 && <BlogDescriptionSection article={article.singleArticle} />}
      <div className="py-2 bg-white">
        <div className=" md:container px-5 md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl mx-auto  md:pb-16 pb-1  ">
          <div className="grid grid-cols-6 gap-8 ">
            <div className="col-span-6 space-y-6 py-12">
              <h6 className=" text-xl text-primary-purple  sm:text-lg sm:mx-auto font-bold tracking-wide  md:text-xl  lg:text-2xl  lg:mx-0">
                Recommended articles
              </h6>
              <div className="relative">
                <div className="grid sm:grid-cols-3 gap-8 grid-rows-1  sm:gap-4">
                  {article.articles.map((article, i) => {
                    return <BlogItem className="" article={article} key={`article__${article.id}`} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDescriptionPage;

{
  /* <Query query={ARTICLE_QUERY} id={id}>
  {({ data: { article } }) => {
    const imageUrl =
      process.env.NODE_ENV !== 'development'
        ? article.image.url
        : process.env.REACT_APP_BACKEND_URL + article.image.url;
    return (

//     );
//   }}
// </Query>; */
}
