import classNames from 'classnames';
import React from 'react';
import bookmark from '../../assets/img/bookmark-icon.svg';
import eye from '../../assets/img/eye-icon.svg';
import bg1 from '../../assets/img/Frame1653.svg';
import key from '../../assets/img/green-key.svg';
import lock from '../../assets/img/green-lock.svg';
import password from '../../assets/img/password-icon.svg';
import ReactGA from 'react-ga'

export function SectionCard({ children, className, title, description, image, full = false }) {
  return (
    <article className={'bg-white w-full  lg:w-custom text-center text-primary-dark ' + className}>
      <div className="inline-flex overflow-hidden h-10 w-10 md:h-12 md:w-12">
        <img src={image} alt="" className="h-full w-full" />
      </div>
      <h2 className="md:mt-3  tracking-tight lg:tracking-normal font-bold  text-gray-900 text-xl md:text-xl lg:text-lg xl:text-xl ">
        {title}
      </h2>
      <p
        className={classNames(
          'mt-3 text-base text-gray-500 sm:mt-5 sm:mx-auto md:mt-5 md:text-xl lg:text-base xl:text-lg lg:mx-0 text-left',
          {
            'sm:max-w-2xl': !full,
          }
        )}
      >
        {description ? description : children}
      </p>
    </article>
  );
}

function SecurityPage() {

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <section className="relative pt-12 md:pt-16">
      <div className="max-w-6xl md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl  mx-auto mt-8 pl-6 pr-6 pb-10  lg:pl-28 xl:pl-36 2xl:pl-12 2xl:pr-0 md:mt-16 lg:mt-20">
        <div className="grid grid-row-1 lg:grid-rows-2 lg:grid-flow-col gap-4 font-site">
          <div className="row-start-1 lg:row-auto lg:col-span-2 ">
            <div>
              <h1 className="text-2xl tracking-tight font-bold sm:max-w-2xl md:max-w-4xl lg:max-w-4xl text-gray-900  md:text-4xl lg:text-3xl xl:text-4xl ">
                Security of your money is our priority
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl lg:text-lg lg:mx-0">
                It has never been easier and safer to manage and grow your
                finances. With our state-of-the-art security measures in place,
                we safeguard your money, your account, and your personal
                information. We work to ensure the confidentiality, integrity,
                and security of your information.
              </p>
            </div>
          </div>
          <div className="row-start-3  lg:row-auto lg:col-span-2 ">
            <div>
              <h3 className="text-lg tracking-tight font-bold  text-gray-900  md:text-3xl lg:text-2xl xl:text-3xl">
                {" "}
                How we protect your
                <br /> information and funds
              </h3>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg   sm:mx-auto md:mt-5 md:text-lg lg:text-lg  lg:mx-0">
                We are empowered by leading regulatory authorities to secure
                your funds and personal information. Developed on the safest
                foundations, no one can touch your cash but you.
              </p>
            </div>
          </div>
          <div className="lg:row-span-2 lg:col-span-2">
            <div className="relative w-full">
              <img className="w-full" src={bg1} alt="icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 bg-primary-gray">
        <div className="container px-4 md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl mx-auto py-2 md:py-16">
          <div className="grid grid-col-6 lg:grid-cols-10 gap-10 ">
            <SectionCard
              className="col-span-6 lg:col-span-4 shadow-lg  px-5 md:px-8  pt-8 pb-10 rounded-2xl  border border-gray-100 "
              title="Bank level encryption"
              description="At the core of Cashlet, your personal data is encrypted with bank-level security encryption
of the highest standards. This ensure that only you have access to it. Your data is never stored
or used anywhere without your consent."
              image={key}
            />
            <SectionCard
              className="col-span-6 lg:col-span-6 shadow-lg px-5 md:px-8 pt-8 pb-10  rounded-2xl  border border-gray-100 "
              title="Regulated by the CMA"
              image={lock}
              full={true}
            >
              The Capital Markets Authority (CMA) is our financial regulator in
              Kenya. We are regulated by the CMA. As a CMA-authorised entity, Cashlet is obliged
              to meet all the rules and regulations of an accredited investment
              business.
              <a
                href="https://www.cma.or.ke/cma-graduates-sycamore-capital-limited-from-the-regulatory-sandbox/"
                target="_blank"
                className="pl-2 text-primary-green font-semibold underline"
              >
                Find out more
              </a>
            </SectionCard>
          </div>
        </div>
      </div>
      <div className="py-8 bg-white">
        <div className="container px-4 md:max-w-2xl  lg:max-w-4.75xl xl:max-w-6xl 2xl:max-w-screen-xl mx-auto py-2 md:py-16">
          <div className="grid grid-cols-1 gap-10 ">
            <SectionCard
              className="col-span-1 shadow-lg px-5  md:px-8 lg:px-20 xl:px-28   pt-8 pb-16  rounded-2xl  border border-gray-100 "
              title="Segregated client funds"
              description="Cashlet does not hold your funds. When you make a deposit, the funds move directly into the collection bank account of our partner fund managers – ICEA Lion Asset Management, Etica Capital and Orient Asset Managers. These are all established fund managers who are monitored and regulated by the Capital Markets Authority (CMA) of Kenya.
This is important because it means we can’t use your money to cover our business expenses or pay off any creditors."
              image={bookmark}
              full={true}
            />
          </div>
        </div>
      </div>
      <div className="py-8 bg-primary-gray">
        <div className="container px-4 md:max-w-2xl  lg:max-w-4.75xl xl:max-w-6xl 2xl:max-w-screen-xl mx-auto py-2 md:py-16">
          <div className="grid grid-col-6  lg:grid-cols-12 xl:grid-cols-10 gap-10 ">
            <SectionCard
              className="col-span-6 lg:col-span-7 xl:col-span-6 shadow-lg px-5  md:px-8  xl:px-14 pt-8 pb-10  rounded-2xl  border border-gray-100 "
              title="Visibility and control over transactions"
              description="You receive notifications for every transaction on your account, so you’re always on top of what’s happening with your money.
Also, we know your data is sensitive to you and we do not and will not sell or rent your personal information to anyone, for any reason, at any time.
"
              image={eye}
              full={true}
            />
            <SectionCard
              className="col-span-6 lg:col-span-5 xl:col-span-4 shadow-lg  px-5  md:px-8 xl:px-14 pt-8 pb-10  rounded-2xl  border border-gray-100 "
              title="Personalized pin code"
              description="Choose a unique 4-PIN code to safeguard your account while ensuring that you never forget it. Alternatively, you can secure your account using your account using your unique fingerprint."
              image={password}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecurityPage;
